import { AxiosResponse } from 'axios';
import { api } from './axios';
import { MessageString } from '../@types/messages';

const TRANSACTION_ENDPOINT = '/transactions';

const monthMap: { [key: string]: number } = {
  janeiro: 0,
  fevereiro: 1,
  março: 2,
  abril: 3,
  maio: 4,
  junho: 5,
  julho: 6,
  agosto: 7,
  setembro: 8,
  outubro: 9,
  novembro: 10,
  dezembro: 11,
};

const getFormattedOperations = (
  months: string[],
  list: Array<{ name: string; balance: number }> = [],
  totalBalance?: number,
  distributeIfEmpty: boolean = false
) => {
  const monthlyValues = new Array(months.length).fill(0);

  if (list.length === 0 && totalBalance && distributeIfEmpty) {
    const monthlyAverage = totalBalance / months.length;
    return monthlyValues.map(() => monthlyAverage);
  }

  list.forEach((item) => {
    const monthIndex = months.indexOf(item.name);
    if (monthIndex !== -1) {
      monthlyValues[monthIndex] += Number(item.balance) || 0;
    }
  });

  return monthlyValues;
};

const getInfoByMonth = (report: any) => {
  if (!report || typeof report !== 'object') return {};

  const transactions = report.transactions || { dataPoints: [], balance: 0 };
  const failed = report.failed || { dataPoints: [], balance: 0 };
  const cancelled = report.cancelled || { dataPoints: [], balance: 0 };

  const transactionsPoints = transactions.dataPoints || [];
  const cancelledPoints = cancelled.dataPoints || [];
  const failedPoints = failed.dataPoints || [];

  if (!transactionsPoints.length && !cancelledPoints.length && !failedPoints.length) {
    return {
      response: {
        data: [],
        categories: [],
        labels: [],
      },
    };
  }

  const allMonths = new Set<string>(transactionsPoints.map((data) => data.name));

  const sortedMonths = Array.from<string>(allMonths).sort(
    (a, b) => monthMap[a.toLowerCase()] - monthMap[b.toLowerCase()]
  );

  const resultSuccess = getFormattedOperations(sortedMonths, transactionsPoints);
  const resultCancelled = getFormattedOperations(sortedMonths, cancelledPoints, cancelled.balance, true);
  const resultFail = getFormattedOperations(sortedMonths, failedPoints, failed.balance, true);

  return {
    response: {
      data: [
        {
          name: 'Efetivados',
          type: 'column',
          data: resultSuccess,
        },
        {
          name: 'Cancelados',
          type: 'column',
          data: resultCancelled,
        },
        {
          name: 'Não efetivados',
          type: 'column',
          data: resultFail,
        },
      ],
      categories: sortedMonths,
      labels: sortedMonths,
    },
  };
};

export const getFormattedChartData = (report: any) => {
  if (!report) {
    return {
      response: {
        data: [],
        categories: [],
        labels: [],
      },
    };
  }

  const monthlyData = getInfoByMonth(report);

  if (!monthlyData.response) {
    return {
      response: {
        data: [],
        categories: [],
        labels: [],
      },
    };
  }

  return monthlyData;
};

export async function deleteTransaction({ id, password }: { id: string; password: string }) {
  const queryStrings = {
    pin: password,
  };
  await api
    .delete(`${TRANSACTION_ENDPOINT}/${id}`, {
      params: queryStrings,
    })
    .catch((error) => {
      if (error.response) {
        throw new Error(error?.response?.data?.message as MessageString);
      }
    });
}

export async function getCsvDataTransactions({
  _end,
  _order,
  _sort,
  _start,
  period,
  authorizer,
  status,
  csat,
}: {
  _end: number;
  _order: string;
  _sort: string;
  _start: number;
  period: string;
  authorizer: string;
  status: string;
  csat: string;
}) {
  const queryStrings: any = {
    _end,
    _order,
    _sort,
    _start,
    period,
  };

  authorizer !== 'ALL' && (queryStrings.authorizer = authorizer);
  status !== 'ALL' && (queryStrings.status = status);
  csat !== undefined && (queryStrings.csat = csat);

  const response: void | AxiosResponse<any> = await api
    .get(TRANSACTION_ENDPOINT, {
      params: queryStrings,
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        throw new Error(error?.response?.data?.message as MessageString);
      }
    });
  return response?.data;
}
