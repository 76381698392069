import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  Skeleton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  getIdentificationSuccess,
  updateDirectionOption,
  updateSortOption,
  resetIdentificationsStates,
} from '../../redux/slices/identifications';
import { RootState } from '../../redux/store';
import { PATH_DASHBOARD } from '../../routes/paths';

const columns = [
  { id: 'createdAt', field: 'createdAt', sortable: true, label: 'Criado em', width: 120, align: 'center' },
  { id: 'name', label: 'Nome', width: 180, align: 'left' },
  { id: 'cpf', label: 'CPF', width: 80, align: 'center' },
  { id: 'id', label: 'ID da Transação', width: 180, align: 'center' },
  { id: 'place', label: 'Estabelecimento', width: 180, align: 'center' },
];

export function IdentificationsDataList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const initialPage = 0;
  const initialRows = 10;
  const pageOptions = [10, 25, 50];
  const [direction, setDirection] = useState(true);
  const [page, setPage] = useState(initialPage);
  const [lastPage, setLastPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [lastRows, setLastRows] = useState(initialRows);
  const [identificationsData, setIdentificationsData]: any = useState([]);
  const {
    identifications,
    headerCount,
    period,
    cpf,
    place,
    users,
    filterSubmit,
    _sort,
    _order,
    resetPagination,
    isRequestingIdentifications,
  } = useSelector((state: RootState) => state.identifications);

  const [sort, setSort] = useState(_sort);

  useEffect(() => {
    dispatch(
      getIdentificationSuccess({
        sort,
        order: _order,
        pageStart: 0,
        pageSize: 10,
        period,
        cpf,
        place,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterSubmit) {
      dispatch(
        getIdentificationSuccess({
          sort,
          order: _order,
          pageStart: 0,
          pageSize: 10,
          period,
          cpf,
          place,
        })
      );
    }
  }, [dispatch, filterSubmit, period, cpf, place, sort, _order, direction]);

  const minArrayLength = 1;

  const handleChangePage = (_event, newPage) => {
    setLastPage(page);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLastRows(rowsPerPage);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDirection = (field: string) => {
    dispatch(updateDirectionOption(!direction));
    setDirection(!direction);
    dispatch(updateSortOption(field));
    setSort(field);
  };

  const handleClick = (_event, id) => {
    history.push(`${PATH_DASHBOARD.clients.root}/${id}`);
    sessionStorage.setItem('previous', JSON.stringify('identification'));
  };

  useEffect(() => {
    if (resetPagination === true) {
      setPage(initialPage);
      setRowsPerPage(10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPagination]);

  useEffect(() => {
    const factorToArray = 1;
    const secondPage = 1;

    const end = (page + factorToArray) * rowsPerPage;
    const start = end - rowsPerPage;
    if (
      page !== initialPage ||
      rowsPerPage !== initialRows ||
      lastPage === secondPage ||
      lastRows === pageOptions[secondPage]
    ) {
      void dispatch(
        getIdentificationSuccess({
          sort: 'createdAt',
          order: 'ASC',
          pageStart: start,
          pageSize: end,
          period,
          cpf,
          place,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (isRequestingIdentifications) {
      setIdentificationsData(
        [...Array(initialRows)].map(() => ({
          createdAt: <Skeleton animation="wave" variant="rectangular" width={120} height={30} />,
          name: <Skeleton animation="wave" variant="rectangular" width={180} height={30} />,
          cpf: <Skeleton animation="wave" variant="rectangular" width={80} height={30} />,
          id: <Skeleton animation="wave" variant="rectangular" width={180} height={30} />,
          place: <Skeleton animation="wave" variant="rectangular" width={50} height={30} />,
        }))
      );
    } else {
      setIdentificationsData(
        identifications.map((row) => {
          const created = moment(row.createdAt).format('DD/MM/YYYY HH:MM:SS');
          const user = users?.find((person) => person.id === row.userId);
          const client = `${row.firstName} ${!user ? row.lastName : ''}`;
          return {
            id: row.id,
            userId: row.userId,
            createdAt: created,
            name: client,
            cpf: row.cpf,
            original: row,
            place: row?.place,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifications]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => void dispatch(resetIdentificationsStates()), []);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          {identificationsData.length < minArrayLength && <caption>Nenhum resultado encontrado</caption>}
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                column.sortable ? (
                  <TableCell key={column.id} style={{ width: column.width }}>
                    {column.label}
                    <TableSortLabel
                      active={sort === column.field}
                      direction={direction === true ? 'desc' : 'asc'}
                      onClick={() => handleDirection(column.field)}
                    />
                  </TableCell>
                ) : (
                  <TableCell key={column.id} style={{ width: column.width }}>
                    {column.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {identificationsData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow style={{ cursor: 'pointer' }} hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} onClick={(event) => handleClick(event, row.userId)}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={pageOptions}
        component="div"
        count={headerCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        labelRowsPerPage="Itens por página"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
