import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  Skeleton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getCardsByPlace, updateDirectionOption, resetCardsStates } from '../../redux/slices/cards';
import { RootState } from '../../redux/store';
import { PATH_DASHBOARD } from '../../routes/paths';
import { CardsStatus } from './CardsStatus';
import { CardsCreditType } from './CardsCreditType';

// ----------------------------------------------------------------------

const columns = [
  { id: 'date', label: 'Data de emissão', minWidth: 170, align: 'left' },
  { id: 'picture', label: 'Foto', minWidth: 50, align: 'left' },
  { id: 'client', label: 'Cliente', minWidth: 170, align: 'right' },
  { id: 'type', label: 'Tipo', minWidth: 100, align: 'left' },
  { id: 'balance', label: 'Saldo', minWidth: 100, align: 'left' },
  { id: 'status', label: 'Status', minWidth: 100, align: 'left' },
  { id: 'place', label: 'Estabelecimento', minWidth: 100, align: 'left' },
];

const useStyles = makeStyles(() => ({
  chip: {
    fontWeight: 300,
    paddingRight: '4px',
    paddingLeft: '4px',
    color: '#fff',
  },
  postPaidChip: {
    backgroundColor: '#AA00FF',
  },
  prePaidChip: {
    backgroundColor: '#00838F',
  },
  deletedChip: {
    backgroundColor: 'red',
  },
  blockedChip: {
    backgroundColor: '#FF4842',
  },
  lateChip: {
    backgroundColor: '#FF6F00',
  },
  readyChip: {
    backgroundColor: '#4caf50',
  },
  profileImage: {
    borderRadius: '50%',
    width: '60px',
    maxHeight: '60px',
    objectFit: 'cover',
  },
  icon: {
    color: '#fff !important',
  },
}));

export function CardsDataList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const initialPage = 0;
  const initialRows = 10;
  const pageOptions = [10, 25, 50];
  const [direction, setDirection] = useState(true);
  const [page, setPage] = useState(initialPage);
  const [lastPage, setLastPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [lastRows, setLastRows] = useState(initialRows);
  const [cardsData, setCardsData]: any = useState([]);
  const { _order, headerCount, resetPagination, cards, name, late, users, isRequestingCards } = useSelector(
    (state: RootState) => state.cards
  );
  const minArrayLength = 0;

  const renderPicture = (avatar, name) => (
    <img className={classes.profileImage} src={avatar} alt={`${name} representarion`} />
  );

  const renderBalance = (balance, formattedValue) => {
    const noValue = 0;
    if (balance > noValue) return <span style={{ color: '#11a850' }}>{formattedValue}</span>;
    if (balance < noValue) return <span style={{ color: '#FF6F00' }}>{formattedValue}</span>;
    return formattedValue;
  };

  const handleChangePage = (_event, newPage) => {
    setLastPage(page);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLastRows(rowsPerPage);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDirection = () => {
    dispatch(updateDirectionOption(!direction));
    setDirection(!direction);
  };

  useEffect(() => {
    const selectedGroupPlaces = localStorage?.getItem('group-selected-places-id')?.length
      ? JSON.parse(localStorage.getItem('group-selected-places-id')!)
      : [];
    localStorage.setItem('selected-places-id', JSON.stringify(selectedGroupPlaces));
  }, []);

  useEffect(() => {
    if (resetPagination === true) {
      setPage(initialPage);
      setRowsPerPage(10);
      // window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPagination]);

  const handleClick = (_event, id) => {
    history.push(`${PATH_DASHBOARD.clients.root}/${id}`);
    sessionStorage.setItem('previous', JSON.stringify('cards'));
  };

  useEffect(() => {
    const _sort = 'createdAt';
    const factorToArray = 1;
    const secondPage = 1;

    const end = (page + factorToArray) * rowsPerPage;
    const start = end - rowsPerPage;
    if (
      page !== initialPage ||
      rowsPerPage !== initialRows ||
      lastPage === secondPage ||
      lastRows === pageOptions[secondPage]
    ) {
      void dispatch(getCardsByPlace({ _end: end, _order, _sort, _start: start, q: name, late, user: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (isRequestingCards || !users) {
      setCardsData(
        [...Array(initialRows)].map(() => ({
          date: <Skeleton animation="wave" variant="rectangular" width={170} height={30} />,
          picture: <Skeleton animation="wave" variant="rectangular" width={50} height={30} />,
          client: <Skeleton animation="wave" variant="rectangular" width={170} height={30} />,
          type: <Skeleton animation="wave" variant="rectangular" width={100} height={30} />,
          balance: <Skeleton animation="wave" variant="rectangular" width={100} height={30} />,
          status: <Skeleton animation="wave" variant="rectangular" width={100} height={30} />,
        }))
      );
    } else {
      setCardsData(
        cards.map((row) => {
          const date = moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss');
          const user = users.find((person) => person.id === row.userId);
          const client = `${row.userFirstName} ${user ? user.lastName : ''}`;
          return {
            id: row.id,
            userId: row.userId,
            date,
            picture: renderPicture(row.avatarUrl, client),
            client,
            type:
              row.type === 'PRE_PAID' || row.type === 'POST_PAID' ? (
                <CardsCreditType paymentType={row.type} />
              ) : (
                row.type
              ),
            balance: renderBalance(row.balance, row.balanceFormatted),
            status: <CardsStatus deleted={row.deleted} blocked={row.blocked} late={row.late} />,
            original: row,
            place: row?.placeName,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards, users]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => void dispatch(resetCardsStates()), []);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', padding: 1 }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          {cardsData.length === minArrayLength && <caption>Nenhum resultado encontrado</caption>}
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                column.label === 'Data' ? (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth, cursor: 'pointer' }}>
                    {column.label}
                    <TableSortLabel
                      active={true}
                      direction={direction === true ? 'desc' : 'asc'}
                      onClick={handleDirection}
                    />
                  </TableCell>
                ) : (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {cardsData.map((row, index) => (
              <TableRow style={{ cursor: 'pointer' }} hover role="checkbox" tabIndex={-1} key={index}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} onClick={(event) => handleClick(event, row.userId)}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={pageOptions}
        component="div"
        count={headerCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        labelRowsPerPage="Itens por página"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
