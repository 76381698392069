import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  TextField,
  Button,
  Checkbox,
  Radio,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Grid,
  Typography,
  Select,
  MenuItem,
  RadioGroup,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { isValidCPF } from '@brazilian-utils/brazilian-utils';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import MaskedInput from 'react-text-mask';
import { useSnackbar } from 'notistack';
import { PATH_DASHBOARD } from '../../routes/paths';
import { createNewEmployee } from '../../utils/employeeServices';
import Permissions from '../../hooks/Permissions';
import { Messages } from '../../@types/messages';

const useStyles = makeStyles((theme) => ({
  formButton: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

type EmployeePermissions = {
  CAN_MANAGE_USER: string | boolean;
  CAN_MANAGE_PAYMENT: string | boolean;
  CAN_LOGIN_WITH_PASSWORD: string | boolean;
  CAN_MANAGE_KEY: string | boolean;
  CAN_MANAGE_DEVICE: string | boolean;
  CAN_MANAGE_CLIENT: string | boolean;
  CAN_CANCEL_TRANSACTION: string | boolean;
  CAN_MANAGE_FINANCIAL: string | boolean;
  CAN_MANAGE_POST_PRE?: string | boolean;
};

export const PlaceEmployeePermissions: EmployeePermissions = {
  CAN_MANAGE_USER: 'Gerenciar Funcionários',
  CAN_MANAGE_PAYMENT: 'Listar Transações',
  CAN_LOGIN_WITH_PASSWORD: 'Login com Senha App Payface',
  CAN_MANAGE_KEY: 'Gerenciar Chaves de API',
  CAN_MANAGE_DEVICE: 'Gerenciar Dispositivos',
  CAN_MANAGE_CLIENT: 'Listar Clientes',
  CAN_CANCEL_TRANSACTION: 'Realizar Cancelamentos',
  CAN_MANAGE_FINANCIAL: 'Gerenciar Financeiro',
};

const initialStatePermissions: EmployeePermissions = {
  CAN_MANAGE_USER: false,
  CAN_MANAGE_PAYMENT: false,
  CAN_LOGIN_WITH_PASSWORD: false,
  CAN_MANAGE_KEY: false,
  CAN_MANAGE_DEVICE: false,
  CAN_MANAGE_CLIENT: false,
  CAN_CANCEL_TRANSACTION: false,
  CAN_MANAGE_FINANCIAL: false,
};

export function EmployeesRegisterForm() {
  const firstGroup = 0;
  const user = JSON.parse(localStorage.getItem('@admin:user')!);

  const initialGroupState = localStorage.getItem('group-id')
    ? JSON.parse(localStorage.getItem('group-id')!)
    : user.employments[firstGroup].placeGroupName;

  const initialPlacesByGroupState = () => {
    const filterPlacesByGroup = user?.employments?.filter((place) => place?.placeGroupName === initialGroupState);
    return filterPlacesByGroup?.map((place) => place?.placeName);
  };

  const [selectionListPlaces, setSelectionListPlaces] = useState(initialPlacesByGroupState());
  const [selectedGroup] = useState(initialGroupState);

  useEffect(() => {
    const hasGroupSelected = localStorage?.getItem('group-id')!;
    const hasPlacesSelected = localStorage?.getItem('selected-places-id')!;

    if (!hasGroupSelected || !selectionListPlaces || !hasPlacesSelected || !hasPlacesSelected?.length) {
      setSelectedPlaces([]);
      localStorage.setItem('selected-places-id', JSON.stringify([]));
      localStorage.setItem('group-id', JSON.stringify(selectedGroup));
      const filterPlacesByGroup = user?.employments?.filter((place) => place?.placeGroupName === selectedGroup);
      const mapPlacesByGroup = filterPlacesByGroup?.map((place) => place?.placeName);
      setSelectionListPlaces(mapPlacesByGroup);
    } else if (hasGroupSelected) {
      hasPlacesSelected ?? setSelectedPlaces(hasPlacesSelected);
      const filterPlacesByGroup = user?.employments?.filter((place) => place?.placeGroupName === selectedGroup);
      const mapPlacesByGroup = filterPlacesByGroup?.map((place) => place?.placeName);
      setSelectionListPlaces(mapPlacesByGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialSelectedPlaces = localStorage?.getItem('selected-places-id')?.length
    ? JSON.parse(localStorage.getItem('selected-places-id')!)
    : [];

  const [selectedPlaces, setSelectedPlaces] = useState(initialSelectedPlaces);
  let updatedPlaces = [];

  const renderPlaceToggleList = (list) =>
    list.map((item, index) => (
      <MenuItem
        key={index}
        value={item}
        disableRipple
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onClick={() => handlePlaceToggle(item)}
      >
        <span>{item}</span>
        <Checkbox checked={selectedPlaces.includes(item)} sx={{ pointerEvents: 'none' }} />
      </MenuItem>
    ));

  const handlePlaceToggle = (place) => {
    setSelectedPlaces((prevSelected) => {
      updatedPlaces = prevSelected.includes(place)
        ? prevSelected.filter((data) => data !== place)
        : [...prevSelected, place];
      localStorage.setItem('selected-places-id', JSON.stringify(updatedPlaces));
      return updatedPlaces;
    });
  };

  const history = useHistory();
  const classes = useStyles();
  const [permissions, setPermissions] = useState(initialStatePermissions);
  const [click, setClick] = useState(false);
  const [cpfError, setCpfError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [checkboxError, setCheckboxError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [cpf, setCpf] = useState('');
  const [password, setPassword] = useState('');
  const [requestingCreateEmployee, setRequestingCreateEmployee] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const validatePassword = (password: string) => {
    if (!password) {
      setPasswordError(Messages.PASSWORD_FORMAT_INFO);
    } else {
      setPasswordError('');
    }
  };

  if (Permissions.hasFeature('HAS_POST_PAID')) {
    PlaceEmployeePermissions.CAN_MANAGE_POST_PRE = 'Gerenciar pós/pré-pago';
    initialStatePermissions.CAN_MANAGE_POST_PRE = false;
  }

  const validateCpf = (cpf: string) => {
    const isCPFValid = isValidCPF(cpf);
    if (isCPFValid === false) {
      setCpfError('CPF inválido');
    } else {
      setCpfError('');
    }
  };

  const validateCheckbox = () => {
    const permissionsValues = Object.values(permissions);
    const findChecked = permissionsValues.some((permission) => permission === true);
    if (findChecked === false) {
      setCheckboxError(Messages.NO_PERMISSIONS_SELECTED_INFO);
    } else {
      setCheckboxError('');
    }
  };

  const handleCreateEmployee = (event) => {
    event.preventDefault();
    setRequestingCreateEmployee(true);
    createNewEmployee({ permissions, cpf, password })
      .then(() => {
        enqueueSnackbar(Messages.EMPLOYER_CREATED, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        history.push(PATH_DASHBOARD.employees.root);
      })
      .catch((error) => {
        enqueueSnackbar(Messages[error.message], {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      })
      .finally(() => {
        setRequestingCreateEmployee(false);
      });
  };

  const handleChange = (event) => {
    const { name, type, checked, value } = event.target;
    const valueType = type === 'checkbox' ? checked : value;
    if (name === 'password') {
      validatePassword(valueType);
      setPassword(valueType);
    } else if (name === 'cpf') {
      validateCpf(valueType);
      setCpf(valueType);
    } else {
      setClick(true);
      setPermissions({ ...permissions, [name]: valueType });
    }
  };

  const handleClickPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleBackEmployeePage = () => {
    history.push(PATH_DASHBOARD.employees.root);
  };

  useEffect(() => {
    if (click === true) {
      validateCheckbox();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  const [selectedAddModeOption, setSelectedAddModeOption] = useState('');

  const AccessGroupSelection = () => {
    const handleChange = (event) => {
      const placesToAccess = event.target.value === 'entireNetwork' ? selectionListPlaces : updatedPlaces;
      setSelectedPlaces(placesToAccess);
      setSelectedAddModeOption(event.target.value);
      localStorage.setItem('selected-places-id', JSON.stringify(placesToAccess));
    };

    return (
      <RadioGroup value={selectedAddModeOption} onChange={handleChange}>
        <FormControlLabel
          value="entireNetwork"
          control={<Radio name="accessGroupPlaces" color="primary" size="small" />}
          label={`Para toda a rede ${selectedGroup} até o momento`}
        />
        <FormControlLabel
          value="selectedStores"
          control={<Radio name="accessGroupPlaces" color="primary" size="small" />}
          label="Apenas para lojas selecionadas"
        />
      </RadioGroup>
    );
  };

  return (
    <Box sx={{ mb: 1, mt: 1, display: 'flex' }}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <MaskedInput
          mask={[/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
          placeholder="___.___.___-__"
          id="cpf"
          name="cpf"
          className={classes.formControl}
          onChange={(event) => handleChange(event)}
          render={(ref: any, props: any) => (
            <TextField
              {...props}
              style={{ minWidth: '282px' }}
              required={true}
              inputRef={ref}
              label="CPF funcionário"
              error={!!cpfError}
              helperText={cpfError}
            />
          )}
        />
        <FormControl required error={!!checkboxError} component="fieldset" className={classes.formControl}>
          <FormGroup>
            <Typography sx={{ mt: 2 }} variant="subtitle1">
              Permissões *
            </Typography>
            <Grid container spacing={1} sx={{ mt: 1, mb: 3, mx: 0 }}>
              {Object.values(PlaceEmployeePermissions).map((item, index) => {
                const foundIndexOf = Object.values(PlaceEmployeePermissions).findIndex(
                  (permission) => permission === item
                );
                const filterState = Object.keys(PlaceEmployeePermissions)[foundIndexOf];
                return (
                  <Grid key={index} item xs={12} md={4}>
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={permissions[filterState]}
                          onChange={handleChange}
                          name={filterState}
                          color="primary"
                          size="small"
                        />
                      }
                      label={item}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Typography sx={{ mt: 2 }} variant="subtitle1">
              Adicionar acesso à: *
            </Typography>
            <Grid container spacing={1} sx={{ mt: 1, mb: 3, mx: 0 }}>
              <Grid item xs={12} md={4}>
                <AccessGroupSelection />

                {selectedAddModeOption === 'selectedStores' && (
                  <FormControl variant="outlined" sx={{ width: '100%', marginTop: 2 }}>
                    <Select
                      id="selectedPlacesByGroup"
                      name="selectedPlacesByGroup"
                      labelId="selectedPlacesByGroup-label"
                      displayEmpty
                      multiple
                      value={selectedPlaces}
                      renderValue={(selected) => (selected.length > 0 ? selected.join(', ') : 'Selecione')}
                    >
                      {renderPlaceToggleList(selectionListPlaces)}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </FormGroup>
          {checkboxError && <FormHelperText>{checkboxError}</FormHelperText>}
        </FormControl>

        <FormControl className={classes.formControl}>
          <Typography sx={{ mb: 2 }} variant="subtitle1">
            Senha Payface de 4 dígitos
          </Typography>
          <TextField
            required={true}
            name="password"
            id="password"
            label="Sua senha Payface ツ"
            variant="outlined"
            error={!!passwordError}
            helperText={passwordError}
            type={showPassword ? 'number' : 'password'}
            onChange={(event) => handleChange(event)}
            inputProps={{
              maxLength: 4,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickPassword}>
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: 3,
          }}
        >
          <Button
            type="button"
            color="primary"
            variant="outlined"
            size="medium"
            startIcon={<ArrowBackIcon />}
            className={classes.formButton}
            onClick={handleBackEmployeePage}
          >
            Voltar
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={
              !!(
                cpfError ||
                passwordError ||
                checkboxError ||
                !cpf ||
                !password ||
                Object.values(permissions).every((permission) => permission === false)
              )
            }
            size="medium"
            startIcon={<SaveIcon />}
            className={classes.formButton}
            onClick={(event) => handleCreateEmployee(event)}
            pending={requestingCreateEmployee}
          >
            Salvar
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
