import { useEffect } from 'react';
import { Card } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getReport, getSwitchOptions, getProfile, getTransactions } from '../../redux/slices/transactions';
import { RootState } from '../../redux/store';
import { resetDataList } from '../../redux/slices/global';
import { TransactionsDataList, TransactionsFormFilter } from '../_transactions';

// ----------------------------------------------------------------------

type ParamTypes = {
  id: string;
};

export function ClientsPanelTransactions() {
  const dispatch = useDispatch();
  const { id } = useParams<ParamTypes>();
  const { period, _order, _start, _end, flag, product, status, resetPagination } = useSelector(
    (state: RootState) => state.transactions
  );
  const { reset } = useSelector((state: RootState) => state.global);

  useEffect(() => {
    dispatch(getProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const _sort = 'createdAt';
    if (reset === true) {
      void dispatch(resetDataList(false));
    }
    void dispatch(getReport(period));
    void dispatch(getSwitchOptions({ _end, _order, _sort, _start }));
    void dispatch(
      getTransactions({
        _end,
        _order,
        _sort,
        _start,
        period,
        authorizer: flag,
        product,
        status,
        user: id,
        csat: '',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, flag, _order, reset, status, resetPagination]);

  return (
    <Card sx={{ p: 4 }}>
      <TransactionsFormFilter />
      <TransactionsDataList />
    </Card>
  );
}
