import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  IconButton,
  Box,
  Chip,
  Tooltip,
  Skeleton,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateDirectionOption,
  getEmployeesByPlace,
  resetEmployeesStates,
  updateSortOption,
} from '../../redux/slices/employees';
import { RootState } from '../../redux/store';
import { EmployeesDetails } from './EmployeesDetails';
import { DialogToDisable } from './DialogToDisable';
import Permissions from '../../hooks/Permissions';

const columns = [
  { id: 'expand', field: 'expand', label: '', width: 20, align: 'left' },
  { id: 'user', field: 'firstName', label: 'Usuário', width: 200, align: 'left', sortable: true },
  { id: 'permissions', field: 'permissions', label: 'Permissões', width: 200, align: 'left' },
  { id: 'places', field: 'places', label: 'Acesso aos estabelecimentos', width: 300, align: 'left' },
  { id: 'manager', field: 'actions', label: 'Ações', width: 150, align: 'center' },
];

const useStyles = makeStyles(() => ({
  chip: {
    fontWeight: 300,
    paddingRight: '4px',
  },
}));

type EmployeePermissions = {
  CAN_MANAGE_USER: string | boolean;
  CAN_MANAGE_PAYMENT: string | boolean;
  CAN_LOGIN_WITH_PASSWORD: string | boolean;
  CAN_MANAGE_KEY: string | boolean;
  CAN_MANAGE_DEVICE: string | boolean;
  CAN_MANAGE_CLIENT: string | boolean;
  CAN_CANCEL_TRANSACTION: string | boolean;
  CAN_MANAGE_FINANCIAL: string | boolean;
  CAN_MANAGE_POST_PRE?: string | boolean;
};

const PlaceEmployeePermissions: EmployeePermissions = {
  CAN_MANAGE_USER: 'Gerenciar Funcionários',
  CAN_MANAGE_PAYMENT: 'Listar Transações',
  CAN_LOGIN_WITH_PASSWORD: 'Login com Senha App Payface',
  CAN_MANAGE_KEY: 'Gerenciar Chaves de API',
  CAN_MANAGE_DEVICE: 'Gerenciar Dispositivos',
  CAN_MANAGE_CLIENT: 'Listar Clientes',
  CAN_CANCEL_TRANSACTION: 'Realizar Cancelamentos',
  CAN_MANAGE_FINANCIAL: 'Gerenciar Financeiro',
};

const initialPermissionsStates: EmployeePermissions = {
  CAN_MANAGE_USER: false,
  CAN_MANAGE_PAYMENT: false,
  CAN_LOGIN_WITH_PASSWORD: false,
  CAN_MANAGE_KEY: false,
  CAN_MANAGE_DEVICE: false,
  CAN_MANAGE_CLIENT: false,
  CAN_CANCEL_TRANSACTION: false,
  CAN_MANAGE_FINANCIAL: false,
};

if (Permissions.hasFeature('HAS_POST_PAID')) {
  PlaceEmployeePermissions.CAN_MANAGE_POST_PRE = 'Gerenciar pós/pré-pago';
  initialPermissionsStates.CAN_MANAGE_POST_PRE = false;
}

type EmployeesDataListProps = {
  onEditEmployee: (employee: any) => void;
};

export function EmployeesDataList({ onEditEmployee }: EmployeesDataListProps) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const initialPage = 0;
  const initialRows = 10;
  const pageOptions = [5, 10, 25, 50];
  const [direction, setDirection] = useState(true);
  const [page, setPage] = useState(initialPage);
  const [lastPage, setLastPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [lastRows, setLastRows] = useState(initialRows);
  const [open, setOpen]: any = useState({
    status: false,
    reference: '',
  });
  const [openDisableDialog, setOpenDisableDialog] = useState({
    status: false,
    original: [],
  });
  const { _order, employees, headerCount, deleted, resetPagination, isRequestingEmployees, _sort } = useSelector(
    (state: RootState) => state.employees
  );

  const [sort, setSort] = useState(_sort);
  const minArrayLength = 1;

  const renderPermissions = (permissions, _index) => {
    const permissionsKeys = Object.keys(PlaceEmployeePermissions);
    const permissionsValues = Object.values(PlaceEmployeePermissions);
    const friendlyNamePermissionsMapped = permissions.map((item) => {
      const foundIndexOf = permissionsKeys.findIndex((permission) => permission === item);
      return permissionsValues[foundIndexOf];
    });

    return friendlyNamePermissionsMapped.map(
      (item, index) =>
        item && (
          <Chip
            className={classes.chip}
            sx={{ mx: 0.75, my: 0.75 }}
            key={index}
            size="small"
            icon={<CheckIcon />}
            label={item}
          />
        )
    );
  };

  const renderPlaces = (places) => {
    if (!places) return null;
    return places.map((place, index) => (
      <Chip className={classes.chip} sx={{ mx: 0.75, my: 0.75 }} key={index} size="small" label={place.name} />
    ));
  };

  const handlePopover = (_event, index) => {
    setOpen({ ...open, status: !open.status, reference: index });
  };

  const handleEditPermissions = (_event, _index, row) => {
    const employeeData = {
      cpf: row.cpf,
      permissions: row.permissions,
      places: row.places,
      deleted: row.deleted,
      id: row.id,
    };
    onEditEmployee(employeeData);
  };

  const handleDisableEmployee = (_event, _index, row) => {
    setOpenDisableDialog({ ...openDisableDialog, status: !openDisableDialog.status, original: row });
  };

  const renderManagementOptions = (index, row) => (
    <Box component="div" display="block">
      <Tooltip title="Editar">
        <IconButton aria-label="edit" onClick={(event) => handleEditPermissions(event, index, row)}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={row.deleted === false ? 'Desativar' : 'Ativar'}>
        <IconButton aria-label="disable" onClick={(event) => handleDisableEmployee(event, index, row)}>
          {row.deleted === true ? <BlockIcon color="error" fontSize="small" /> : <BlockIcon fontSize="small" />}
        </IconButton>
      </Tooltip>
    </Box>
  );

  const renderExpand = (index: number) => (
    <Box component="div" display="flex" alignItems="center">
      <IconButton aria-label="expand" onClick={(event) => handlePopover(event, index)}>
        {open.status === true && index === open.reference ? (
          <ExpandLessIcon fontSize="small" />
        ) : (
          <ExpandMoreIcon fontSize="small" />
        )}
      </IconButton>
    </Box>
  );

  const employeesData = isRequestingEmployees
    ? [...Array(initialRows)].map(() => ({
        expand: <Skeleton animation="wave" variant="rectangular" width={50} height={30} />,
        user: <Skeleton animation="wave" variant="rectangular" width={180} height={30} />,
        permissions: <Skeleton animation="wave" variant="rectangular" width={300} height={30} />,
        places: <Skeleton animation="wave" variant="rectangular" width={300} height={30} />,
        manager: <Skeleton animation="wave" variant="rectangular" width={130} height={30} />,
      }))
    : employees.map((row, index) => ({
        expand: renderExpand(index),
        id: row.id,
        cpf: row.cpf,
        status: row.deleted,
        deleted: row.deletedAt,
        user: `${row.firstName} ${row.lastName}`,
        permissions: renderPermissions(row.permissions, index),
        places: renderPlaces(row.places),
        manager: renderManagementOptions(index, row),
        original: row,
      }));

  const handleChangePage = (_event, newPage) => {
    setLastPage(page);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLastRows(rowsPerPage);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDirection = (field: string) => {
    dispatch(updateDirectionOption(!direction));
    setDirection(!direction);
    dispatch(updateSortOption(field));
    setSort(field);
  };

  useEffect(() => {
    const selectedGroupPlaces = localStorage?.getItem('group-selected-places-id')?.length
      ? JSON.parse(localStorage.getItem('group-selected-places-id')!)
      : [];
    localStorage.setItem('selected-places-id', JSON.stringify(selectedGroupPlaces));
  }, []);

  useEffect(() => {
    if (resetPagination === true || deleted === true) {
      setPage(initialPage);
      setRowsPerPage(10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPagination, deleted]);

  useEffect(() => {
    const factorToArray = 1;
    const secondPage = 1;

    const end = (page + factorToArray) * rowsPerPage;
    const start = end - rowsPerPage;
    if (
      page !== initialPage ||
      rowsPerPage !== initialRows ||
      lastPage === secondPage ||
      lastRows === pageOptions[secondPage]
    ) {
      void dispatch(getEmployeesByPlace({ _end: end, _order, _sort: sort, _start: start, deleted }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sort]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => void dispatch(resetEmployeesStates()), []);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <DialogToDisable openDialog={openDisableDialog} setOpenDialog={setOpenDisableDialog} />
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          {employeesData.length < minArrayLength && <caption>Nenhum resultado encontrado</caption>}
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                column.sortable ? (
                  <TableCell key={column.id} style={{ width: column.width }}>
                    {column.label}
                    <TableSortLabel
                      active={sort === column.field}
                      direction={direction === true ? 'desc' : 'asc'}
                      onClick={() => handleDirection(column.field)}
                    />
                  </TableCell>
                ) : (
                  <TableCell key={column.id} style={{ minWidth: column.width }}>
                    {column.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {employeesData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return <TableCell key={column.id}>{value}</TableCell>;
                  })}
                </TableRow>
                <EmployeesDetails data={row} position={index} open={open} />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={pageOptions}
        component="div"
        count={headerCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        labelRowsPerPage="Itens por página"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
