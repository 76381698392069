import { createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { store } from '../store';
import { api } from '../../utils/axios';

const identificationsEndpoints = {
  identifications: '/identifications',
  chart: '/identifications/report/chart',
  report: '/identifications/report',
};

type IdentificationsStates = {
  isRequestingIdentifications: boolean;
  errorMessageIdentifications: string;
  isLoading: boolean;
  successSave: boolean;
  filterSubmit: boolean;
  error: boolean;
  count: number;
  period: string;
  cpf: string;
  isRequestingReport: boolean;
  errorMessageReport: string;
  isRequestingUser: boolean;
  isRequestingUsers: boolean;
  errorMessageUser: string;
  errorMessageUsers: string;
  user: any;
  users: any;
  _order: string;
  _end: number;
  _start: number;
  _sort: string;
  report: any;
  headerCount: number;
  identifications: any;
  identificationsChart: any;
  identificationsReport: any;
  resetPagination: boolean;
  place: string;
};

const initialState: IdentificationsStates = {
  isRequestingIdentifications: false,
  errorMessageIdentifications: '',
  isLoading: false,
  successSave: false,
  filterSubmit: false,
  error: false,
  count: 0,
  period: 'ALL',
  cpf: '',
  isRequestingReport: false,
  errorMessageReport: '',
  isRequestingUser: false,
  errorMessageUser: '',
  isRequestingUsers: false,
  errorMessageUsers: '',
  user: {},
  users: [],
  headerCount: 0,
  report: {},
  identifications: [],
  identificationsChart: [],
  identificationsReport: [],
  _order: 'DESC',
  _end: 10,
  _start: 0,
  _sort: 'createdAt',
  resetPagination: false,
  place: '',
};

const slice = createSlice({
  name: 'identification',
  initialState,
  reducers: {
    requestingIdentifications(state) {
      state.isRequestingIdentifications = true;
    },
    getIdentificationsSuccess(state, action) {
      state.isRequestingIdentifications = false;
      state.identifications = action.payload;
    },
    getIdentificationsFail(state, action) {
      state.isRequestingIdentifications = false;
      state.errorMessageIdentifications = action.payload;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasChangeFilter(state, action) {
      state.filterSubmit = action.payload;
    },
    getIdentificationData(state, action) {
      state.isLoading = false;
      state.identifications = action.payload;
      if (!action.payload || action.payload.length === 0) {
        state.count = 0;
        state.headerCount = 0;
      }
    },
    getIdentificationDataChart(state, action) {
      state.isLoading = false;
      state.identificationsChart = action.payload;
    },
    getIdentificationDataReport(state, action) {
      state.isLoading = false;
      state.identificationsReport = action.payload;
    },
    getFilters(state, action) {
      const { period, cpf, place } = action.payload;
      state.period = period;
      state.cpf = cpf;
      state.place = place;
    },
    getOrder(state, action) {
      state._order = action.payload;
    },
    setSort(state, action) {
      state._sort = action.payload;
    },
    getPaginationStartAndEnd(state, action) {
      const { start, end } = action.payload;
      state._end = end;
      start._start = start;
    },
    getIdentificationsHeaders(state, action) {
      state.headerCount = action.payload;
    },
    resetPagination(state, action) {
      state.resetPagination = action.payload;
    },
    requestingReport(state) {
      state.isRequestingReport = true;
    },
    getReportSuccess(state, action) {
      state.isRequestingReport = false;
      state.report = action.payload;
    },
    getReportFail(state, action) {
      state.isRequestingReport = false;
      state.errorMessageReport = action.payload;
    },
    requestingUsers(state) {
      state.isRequestingUsers = true;
    },
    getUsersSuccess(state, action) {
      state.isRequestingUsers = false;
      state.users = action.payload;
    },
    getUsersFail(state, action) {
      state.isRequestingUsers = false;
      state.errorMessageUsers = action.payload;
    },
    requestingUser(state) {
      state.isRequestingUser = true;
    },
    getUserSuccess(state, action) {
      state.isRequestingUser = false;
      state.user = action.payload;
    },
    getUserFail(state, action) {
      state.isRequestingUser = false;
      state.errorMessageUser = action.payload;
    },
    getTransactionsHeaders(state, action) {
      state.headerCount = action.payload;
    },
    resetAllStates(state) {
      state.period = 'ALL';
      state.cpf = '';
    },
  },
});

type IdentificationProps = {
  period: string;
  cpf: string;
  sort: string;
  pageStart: number;
  pageSize: number;
  order: string;
  place: string;
};

export default slice.reducer;

export function getIdentificationSuccess(props: IdentificationProps) {
  const { sort, pageStart, pageSize, order, period = 'ALL', cpf, place } = props;
  const params = {
    _sort: sort,
    _end: pageSize,
    _start: pageStart,
    _order: order,
    period,
    cpf,
    place,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading);
    try {
      const response: void | AxiosResponse<any> = await api.get(identificationsEndpoints.identifications, { params });

      if (!response?.data || response.data.length === 0) {
        dispatch(slice.actions.getIdentificationData([]));
        dispatch(slice.actions.getTransactionsHeaders(0));
        return;
      }

      const xTotalCount: number = Number(response?.headers['x-total-count']);

      dispatch(slice.actions.getIdentificationData(response?.data));
      dispatch(slice.actions.getTransactionsHeaders(Number(xTotalCount)));
    } catch (error) {
      dispatch(slice.actions.getIdentificationData([]));
      dispatch(slice.actions.getTransactionsHeaders(0));
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getIdentificationSuccessReportChart({ period = 'ALL', cpf }: { period: string; cpf?: string }) {
  const params = {
    period,
    cpf,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading);
    try {
      const response: void | AxiosResponse<any> = await api.get(identificationsEndpoints.chart, { params });

      if (!response?.data || response.data.length === 0) {
        dispatch(slice.actions.getIdentificationDataChart([]));
        return;
      }

      dispatch(slice.actions.getIdentificationDataChart(response?.data));
    } catch (error) {
      dispatch(slice.actions.getIdentificationDataChart([]));
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getIdentificationSuccessReport({ period = 'ALL', cpf }: { period: string; cpf?: string }) {
  const params = { period, cpf };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading);
    try {
      const response: void | AxiosResponse<any> = await api.get(identificationsEndpoints.report, { params });

      if (!response?.data || response.data.length === 0) {
        dispatch(slice.actions.getIdentificationDataReport([]));
        return;
      }

      dispatch(slice.actions.getIdentificationDataReport(response?.data));
    } catch (error) {
      dispatch(slice.actions.getIdentificationDataReport([]));
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateFilterOptions(
  { period, cpf, place }: { period: string; cpf: string; place?: string },
  hasChange
) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.getFilters({ period, cpf, place }));
    dispatch(slice.actions.hasChangeFilter(hasChange));
  };
}

export function updateDirectionOption(direction: boolean) {
  return async () => {
    const { dispatch } = store;
    const order = direction === true ? 'DESC' : 'ASC';
    dispatch(slice.actions.getOrder(order));
  };
}

export function updateSortOption(field: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.setSort(field));
  };
}

export function updatePagination(end: number, start: number) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.getPaginationStartAndEnd({ end, start }));
  };
}

export function resetPaginationData(state) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetPagination(state));
  };
}

export function resetIdentificationsStates() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetAllStates());
  };
}
