import { useState } from 'react';
import { Box, IconButton, Paper, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { EmployeesDataList } from './EmployeesDataList';
import EmployeeEditForm from './EmployeeEditForm';

interface EmployeeManagementProps {
  onViewChange: (isEditing: boolean) => void;
}

export function EmployeeManagement({ onViewChange }: EmployeeManagementProps) {
  const [currentView, setCurrentView] = useState('list');
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleEdit = (employee: any) => {
    setSelectedEmployee(employee);
    setCurrentView('edit');
    onViewChange(true);
  };

  const handleClose = () => {
    setCurrentView('list');
    setSelectedEmployee(null);
    onViewChange(false);
  };
  if (currentView === 'edit' && selectedEmployee) {
    return (
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 1,
            flexWrap: 'wrap',
          }}
        >
          <IconButton onClick={handleClose}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4">Editar Funcionário</Typography>
        </Box>
        <Paper sx={{ p: 3 }}>
          <EmployeeEditForm employee={selectedEmployee} onClose={handleClose} />
        </Paper>
      </Box>
    );
  }

  return <EmployeesDataList onEditEmployee={handleEdit} />;
}
