import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  Skeleton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getRegisteredCustomers, updateDirectionOption, resetClientsStates } from '../../redux/slices/clients';
import { RootState } from '../../redux/store';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const columns = [
  {
    id: 'name',
    label: 'Nome',
    width: 250,
    align: 'left',
  },
  {
    id: 'createdAt',
    label: 'Cliente desde',
    width: 250,
    align: 'left',
  },
  {
    id: 'place',
    label: 'Estabelecimento',
    width: 250,
    align: 'left',
  },
];

export function ClientsDataList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const initialPage = 0;
  const initialRows = 10;
  const pageOptions = [10, 25, 50];
  const [direction, setDirection] = useState(true);
  const [page, setPage] = useState(initialPage);
  const [lastPage, setLastPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [lastRows, setLastRows] = useState(initialRows);

  const { clients, totalCount, _order, isRequestingClients } = useSelector((state: RootState) => state.clients);
  const minArrayLength = 0;

  const clientsData = isRequestingClients
    ? [...Array(initialRows)].map(() => ({
        name: <Skeleton animation="wave" variant="rectangular" width={250} height={30} />,
        createdAt: <Skeleton animation="wave" variant="rectangular" width={250} height={30} />,
      }))
    : clients.map((row) => {
        const created = moment(row.createdAt).format('DD/MM/YYYY HH:MM:SS');
        const user = `${row.firstName} ${row.lastName}`;
        return {
          id: row.id,
          name: user,
          createdAt: created,
          original: row,
          place: row?.place,
        };
      });

  const handleChangePage = (_event, newPage) => {
    setLastPage(page);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLastRows(rowsPerPage);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDirection = () => {
    dispatch(updateDirectionOption(!direction));
    setDirection(!direction);
  };

  const handleClick = (_event, id) => {
    history.push(`${PATH_DASHBOARD.clients.root}/${id}`);
    sessionStorage.setItem('previous', JSON.stringify('clients'));
  };

  useEffect(() => {
    const selectedGroupPlaces = localStorage?.getItem('group-selected-places-id')?.length
      ? JSON.parse(localStorage.getItem('group-selected-places-id')!)
      : [];
    localStorage.setItem('selected-places-id', JSON.stringify(selectedGroupPlaces));
  }, []);

  useEffect(() => {
    dispatch(updateDirectionOption(direction));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  useEffect(() => {
    const _sort = 'createdAt';
    const factorToArray = 1;
    const secondPage = 1;

    const end = (page + factorToArray) * rowsPerPage;
    const start = end - rowsPerPage;
    if (
      page !== initialPage ||
      rowsPerPage !== initialRows ||
      lastPage === secondPage ||
      lastRows === pageOptions[secondPage]
    ) {
      void dispatch(
        getRegisteredCustomers({
          _end: end,
          _order,
          _sort,
          _start: start,
          q: '',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => void dispatch(resetClientsStates()), []);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          {clientsData.length === minArrayLength && <caption>Nenhum resultado encontrado</caption>}
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                column.label === 'Cliente desde' ? (
                  <TableCell key={column.id} style={{ width: column.width }}>
                    {column.label}
                    <TableSortLabel
                      active={true}
                      direction={direction === true ? 'desc' : 'asc'}
                      onClick={handleDirection}
                    />
                  </TableCell>
                ) : (
                  <TableCell key={column.id} style={{ width: column.width }}>
                    {column.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {clientsData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow style={{ cursor: 'pointer' }} hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} onClick={(event) => handleClick(event, row.id)}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={pageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        labelRowsPerPage="Itens por página"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
