import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ComponentsLib } from '@payface-frontends-libs/components';
import { Card, CardHeader, Skeleton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getFormattedChartData } from '../../utils/paymentsServices';
import { merge } from '../../utils/utils';

const { BaseOptionChart } = ComponentsLib.Charts;

export function TransactionsChartCard() {
  const { report, isRequestingReport } = useSelector((state: RootState) => state.transactions);
  const [transactionsData, setTransactionsData]: any = useState([]);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [labels, setLabels] = useState([]);

  const handleOptions = merge(BaseOptionChart(), {
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        borderRadius: 4,
      },
    },
    fill: { type: ['solid', 'solid', 'solid'] },
    labels,
    xaxis: {
      categories,
      tickPlacement: 'between',
    },
    chart: {
      type: 'bar',
      stacked: false,
      redrawOnParentResize: true,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (val: number) => `R$ ${val.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`,
      },
    },
    colors: ['#00AB55', '#FFAB00', '#FF4842'],
  });

  useEffect(() => {
    if (report) {
      setTransactionsData(getFormattedChartData(report));
    } else {
      setTransactionsData({});
      setData([]);
      setCategories([]);
      setLabels([]);
    }
  }, [report]);

  useEffect(() => {
    if (transactionsData.response) {
      setData(transactionsData.response.data);
      setCategories(transactionsData.response.categories);
      setLabels(transactionsData.response.labels);
    }
  }, [transactionsData]);

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column' }}>
      <CardHeader title="Pagamentos" />
      {isRequestingReport ? (
        <Skeleton
          sx={{ display: 'flex', alignSelf: 'center', mb: '20px' }}
          animation="wave"
          variant="text"
          width={250}
          height={360}
        />
      ) : (
        <ReactApexChart type="bar" series={data} options={handleOptions} height={364} />
      )}
    </Card>
  );
}
