import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, Container, Button, CircularProgress, Typography, TextField, Card, FormControl } from '@material-ui/core';

import { Icon } from '@iconify/react';
import baselinePersonAdd from '@iconify/icons-ic/baseline-person-add';
import { ComponentsLib } from '@payface-frontends-libs/components';
import Permissions from '../../hooks/Permissions';
import {
  getRegisteredCustomers,
  updateFilterOptions,
  resetPaginationData,
  resetClientsStates,
} from '../../redux/slices/clients';
import { RootState } from '../../redux/store';
import { resetDataList } from '../../redux/slices/global';
import { PATH_DASHBOARD } from '../../routes/paths';
import { ClientsDataList } from '../../components/_clients/ClientsDataList';
import { FilterButtons } from '../../components/library/FilterButtons';
import { resetCardsStates } from '../../redux/slices/cards';

const { Page } = ComponentsLib.Generics;

const Clients = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterRequesting, setFilterRequesting] = useState(false);
  const [clearRequesting, setClearRequesting] = useState(false);
  const { clients, _end, _order, _start, isRequestingClients, name } = useSelector((state: RootState) => state.clients);
  const { reset } = useSelector((state: RootState) => state.global);

  useEffect(() => {
    if (reset === true) {
      void dispatch(resetDataList(false));
    }
    dispatch(
      getRegisteredCustomers({
        _end,
        _order,
        _sort: 'createdAt',
        _start,
        q: name,
      })
    );
  }, [_order, reset, _end, name]); // eslint-disable-line

  if (!clients) {
    return <CircularProgress />;
  }

  const handleInvite = () => {
    history.push(`${PATH_DASHBOARD.clients.invite}`);
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchTerm !== '') {
      dispatch(resetPaginationData(true));
      dispatch(updateFilterOptions(searchTerm));
      setFilterRequesting(true);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (isRequestingClients === false) {
      setFilterRequesting(false);
      setClearRequesting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestingClients]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (reset === true) {
      setSearchTerm('');
      setFilterRequesting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    resetClientsStates();
    resetCardsStates();
  }, []);

  return (
    <Page title="CRM > Clientes">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Clientes
        </Typography>
        <Card sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              px: 1,
              py: 2,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <form
              id="clientsForm"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
              }}
              onSubmit={(event) => handleSubmit(event)}
            >
              <FormControl sx={{ marginRight: 2 }}>
                <TextField
                  id="search"
                  name="search"
                  value={searchTerm}
                  label="Buscar por nome"
                  type="text"
                  variant="outlined"
                  sx={{ width: '380px' }}
                  onChange={(event) => handleSearch(event)}
                />
              </FormControl>
              <FilterButtons
                initialState=""
                filterRequesting={filterRequesting}
                clearRequesting={clearRequesting}
                setFilter={setSearchTerm}
                setClearRequesting={setClearRequesting}
                formId="clientsForm"
                updateFilter={updateFilterOptions}
                resetPagination={resetPaginationData}
              />
            </form>

            <Box sx={{ width: '210px' }}>
              {!Permissions.hasSeveralPlaces() && (
                <Button
                  fullWidth
                  variant="contained"
                  type="button"
                  onClick={handleInvite}
                  startIcon={<Icon icon={baselinePersonAdd} />}
                  sx={{ mr: 2 }}
                  size="medium"
                >
                  Convidar Pessoa
                </Button>
              )}
            </Box>
          </Box>
          <ClientsDataList />
        </Card>
      </Container>
    </Page>
  );
};

export default Clients;
