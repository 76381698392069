import { useEffect, useState } from 'react';
import { ComponentsLib } from '@payface-frontends-libs/components';
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getIdentificationSuccessReportChart } from '../../redux/slices/identifications';
import { RootState } from '../../redux/store';
import { merge } from '../../utils/utils';

const { BaseOptionChart } = ComponentsLib.Charts;

export function IdentificationChart() {
  const dispatch = useDispatch();

  const { identificationsChart, period, cpf } = useSelector((state: RootState) => state.identifications);

  interface ChartData {
    data: number[];
    name: string;
  }

  const [dataChart, setDataChart] = useState<ChartData[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  const handleOptions = merge(BaseOptionChart(), {
    chart: {
      type: 'bar',
      redrawOnParentResize: true,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '100%',
        distributed: true,
      },
    },
    labels,
    legend: {
      show: false,
    },
    xaxis: {
      tickPlacement: 'between',
      spacing: 4,
    },
  });

  useEffect(() => {
    dispatch(
      getIdentificationSuccessReportChart({
        period,
        cpf,
      })
    );
  }, [dispatch, period, cpf]);

  useEffect(() => {
    if (identificationsChart?.identificationsChart?.length > 0) {
      transformDataChart(identificationsChart);
    } else {
      const emptyData = [
        {
          data: [0],
          name: moment().format('DD/MM/YYYY'),
        },
      ];
      const emptyLabel = [moment().format('DD/MM/YY')];

      setDataChart(emptyData);
      setLabels(emptyLabel);
    }
  }, [identificationsChart]);

  const transformDataChart = (identification): Array<string> => {
    if (!identification?.identificationsChart?.length) {
      const emptyData = [
        {
          data: [0],
          name: moment().format('DD/MM/YYYY'),
        },
      ];
      const emptyLabel = [moment().format('DD/MM/YY')];

      setDataChart(emptyData);
      setLabels(emptyLabel);
      return [];
    }

    const dataChartIdentification = identification.identificationsChart;

    const dataChartValues = dataChartIdentification.map((value) => ({
      data: [value.total],
      name: moment(value.created).format('DD/MM/YYYY'),
    }));

    const labelDate = dataChartIdentification.map((value) => moment(value.created).format('DD/MM/YY'));

    setDataChart(dataChartValues);
    setLabels(labelDate);

    return [];
  };

  return (
    <Card>
      <CardHeader title="Identificações com sucesso" />
      <ReactApexChart
        type="bar"
        series={dataChart}
        options={handleOptions}
        height={364}
        {...(!dataChart.length && {
          options: {
            ...handleOptions,
            noData: {
              text: 'Nenhum dado disponível',
              align: 'center',
              verticalAlign: 'middle',
              style: {
                fontSize: '14px',
              },
            },
          },
        })}
      />
    </Card>
  );
}
