import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  Chip,
  Box,
  IconButton,
  Tooltip,
  Skeleton,
} from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getDevicesByPlace, updateDirectionOption, resetDeviceData } from '../../redux/slices/devices';
import { RootState } from '../../redux/store';
import { DevicesDetails } from './DevicesDetails';
import { ActionDialog } from '../library/ActionDialog';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const columns = [
  { id: 'expand', label: '', minWidth: 50, align: 'left' },
  { id: 'name', label: 'Nome', minWidth: 180, align: 'left' },
  { id: 'function', label: 'Função', minWidth: 240, align: 'left' },
  { id: 'deviceUUID', label: 'UUID do Dispositivo', minWidth: 150, align: 'center' },
  { id: 'createdAt', label: 'Data de criação', minWidth: 120, align: 'center' },
  { id: 'manager', label: 'Ação', minWidth: 50, align: 'center' },
  { id: 'place', label: 'Estabelecimento', minWidth: 50, align: 'center' },
];

const useStyles = makeStyles(() => ({
  chip: {
    fontWeight: 300,
    paddingRight: '4px',
    paddingLeft: '4px',
    color: '#fff',
  },
  dualClient: {
    backgroundColor: '#FF8A65',
  },
  dualCashier: {
    backgroundColor: '#687379',
  },
  icon: {
    color: '#fff !important',
  },
}));

export default function DevicesDataList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const initialPage = 0;
  const initialRows = 10;
  const pageOptions = [10, 25, 50];
  const classes = useStyles();
  const [direction, setDirection] = useState(true);
  const [page, setPage] = useState(initialPage);
  const [lastPage, setLastPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [lastRows, setLastRows] = useState(initialRows);
  const [open, setOpen]: any = useState({
    status: false,
    reference: '',
  });
  const [openDeleteDialog, setOpenDeleteDialog]: any = useState({
    status: false,
    id: '',
  });
  const { devices, _order, headerCount, isRequestingDevices } = useSelector((state: RootState) => state.devices);
  const minArrayLength = 0;

  const handlePopover = (_event, index) => {
    setOpen({ ...open, status: !open.status, reference: index });
  };

  const renderExpand = (index: number) => (
    <Box component="div" display="flex" alignItems="center">
      <IconButton aria-label="expand" onClick={(event) => handlePopover(event, index)}>
        {open.status === true && index === open.reference ? (
          <ExpandLessIcon fontSize="small" />
        ) : (
          <ExpandMoreIcon fontSize="small" />
        )}
      </IconButton>
    </Box>
  );

  const renderType = (type) => {
    if (type === 'DUAL_CLIENT')
      return (
        <Chip
          className={`${classes.chip} ${classes.dualClient}`}
          sx={{ mx: 0.75, my: 0.75 }}
          size="small"
          variant="outlined"
          icon={<CameraAltIcon className={classes.icon} />}
          label="Captura"
        />
      );

    return (
      <Chip
        className={`${classes.chip} ${classes.dualCashier}`}
        sx={{ mx: 0.75, my: 0.75 }}
        size="small"
        variant="outlined"
        icon={<ReceiptIcon className={classes.icon} />}
        label="Caixa/PDV"
      />
    );
  };

  const handleEditPage = (id) => {
    void dispatch(resetDeviceData());
    history.push(`${PATH_DASHBOARD.devices.root}/${id}`);
  };

  const handleDeleteDevice = (_event, id) => {
    if (openDeleteDialog.status === false) {
      setOpenDeleteDialog({ ...openDeleteDialog, status: true, id });
    } else {
      setOpenDeleteDialog({ ...openDeleteDialog, status: false, id: '' });
    }
  };

  const renderManagementOptions = (id) => (
    <Box component="div" display="block">
      <Tooltip title="Editar">
        <IconButton aria-label="edit" onClick={() => handleEditPage(id)}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Excluir">
        <IconButton aria-label="delete" onClick={(event) => handleDeleteDevice(event, id)}>
          <DeleteForeverIcon fontSize="small" color="error" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const devicesData = isRequestingDevices
    ? [...Array(initialRows)].map(() => ({
        expand: <Skeleton animation="wave" variant="rectangular" width={50} height={30} />,
        name: <Skeleton animation="wave" variant="rectangular" width={180} height={30} />,
        function: <Skeleton animation="wave" variant="rectangular" width={240} height={30} />,
        deviceUUID: <Skeleton animation="wave" variant="rectangular" width={150} height={30} />,
        createdAt: <Skeleton animation="wave" variant="rectangular" width={120} height={30} />,
        manager: <Skeleton animation="wave" variant="rectangular" width={50} height={30} />,
      }))
    : devices.map((row, index) => {
        const created = moment(row.createdAt).format('DD/MM/YYYY');
        return {
          expand: renderExpand(index),
          id: row.id,
          name: row.name,
          function: renderType(row.function),
          deviceUUID: row.deviceUUID ? row.deviceUUID : '-',
          createdAt: created,
          manager: renderManagementOptions(row.id),
          original: row,
          place: row?.place?.name,
        };
      });

  const handleChangePage = (_event, newPage) => {
    setLastPage(page);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLastRows(rowsPerPage);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDirection = () => {
    dispatch(updateDirectionOption(!direction));
    setDirection(!direction);
  };

  useEffect(() => {
    const selectedGroupPlaces = localStorage?.getItem('group-selected-places-id')?.length
      ? JSON.parse(localStorage.getItem('group-selected-places-id')!)
      : [];
    localStorage.setItem('selected-places-id', JSON.stringify(selectedGroupPlaces));
  }, []);

  useEffect(() => {
    dispatch(updateDirectionOption(direction));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  useEffect(() => {
    const _sort = 'createdAt';
    const factorToArray = 1;
    const secondPage = 1;

    const end = (page + factorToArray) * rowsPerPage;
    const start = end - rowsPerPage;
    if (
      page !== initialPage ||
      rowsPerPage !== initialRows ||
      lastPage === secondPage ||
      lastRows === pageOptions[secondPage]
    ) {
      void dispatch(getDevicesByPlace({ _end: end, _order, _sort, _start: start }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <ActionDialog openDialog={openDeleteDialog} setOpenDialog={setOpenDeleteDialog} type="deleteDevice" />
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          {devicesData.length === minArrayLength && <caption>Nenhum resultado encontrado</caption>}
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                column.label === 'Data de criação' ? (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                    {column.label}
                    <TableSortLabel
                      active={true}
                      direction={direction === true ? 'desc' : 'asc'}
                      onClick={handleDirection}
                    />
                  </TableCell>
                ) : (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {devicesData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return <TableCell key={column.id}>{value}</TableCell>;
                  })}
                </TableRow>
                <DevicesDetails data={row} position={index} open={open} />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={pageOptions}
        component="div"
        count={headerCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        labelRowsPerPage="Itens por página"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
