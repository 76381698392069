import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Card, Typography, Box, Button } from '@material-ui/core';
import { ComponentsLib } from '@payface-frontends-libs/components';
import { Icon } from '@iconify/react';
import baselineAdd from '@iconify/icons-ic/baseline-add';
import DevicesDataList from '../../components/_devices/DevicesDataList';
import Permissions from '../../hooks/Permissions';
import { ExportButton } from '../../components/library/ExportButton';
import { getCsvDataDevices } from '../../utils/devicesServices';
import { RootState } from '../../redux/store';
import { getDevicesByPlace, resetDeviceData } from '../../redux/slices/devices';
import { PATH_DASHBOARD } from '../../routes/paths';
import { resetDataList } from '../../redux/slices/global';

const { Page } = ComponentsLib.Generics;

// ----------------------------------------------------------------------

export default function Devices() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { _order, _end, _start } = useSelector((state: RootState) => state.devices);
  const { reset } = useSelector((state: RootState) => state.global);
  const [exportRequesting, setExportRequesting] = useState(false);

  const handleExport = async () => {
    setExportRequesting(true);
    const end = 1000;
    const start = 0;
    const sort = 'createdAt';
    const result = await getCsvDataDevices({ _end: end, _order, _sort: sort, _start: start });
    if (result) {
      setExportRequesting(false);
      return result;
    }
  };

  const handleClick = () => {
    void dispatch(resetDeviceData());
    history.push(PATH_DASHBOARD.devices.create);
  };

  useEffect(() => {
    const sort = 'createdAt';
    if (reset === true) {
      void dispatch(resetDataList(false));
    }
    void dispatch(getDevicesByPlace({ _end, _order, _sort: sort, _start }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_order, reset]);

  return (
    <Page title="CRM > Dispositivos">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Dispositivos
        </Typography>
        <Card sx={{ display: 'flex', flexDirection: 'column', px: 2, py: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-end', pb: 2, px: 1 }}>
            {!Permissions.hasSeveralPlaces() && (
              <Button
                variant="contained"
                type="button"
                onClick={handleClick}
                startIcon={<Icon icon={baselineAdd} />}
                sx={{ mr: 2 }}
                size="medium"
              >
                Adicionar
              </Button>
            )}
            <Box sx={{ display: 'flex' }}>
              <ExportButton handleExport={handleExport} isRequesting={exportRequesting} name="devices" />
            </Box>
          </Box>
          <Box>
            <DevicesDataList />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
