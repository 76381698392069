import { AxiosResponse } from 'axios';
import { api } from './axios';
import { MessageString } from '../@types/messages';
import { Employee, EmployeeResponse } from '../@types/employee';

const EMPLOYEES_ENDPOINT = '/places-employees';

export async function updatePermissionsEmployee({
  password,
  original,
  placePermissions,
}: {
  password: string;
  original: any;
  placePermissions: Array<{
    placeId: string | number;
    permissions: string[];
  }>;
}) {
  const payload = {
    ...original,
    managePassword: password,
    placePermissions,
  };

  await api
    .put(`${EMPLOYEES_ENDPOINT}/${original.id}`, payload)
    .then((response: AxiosResponse) => response.data)
    .catch((error) => {
      if (error.response) {
        throw new Error(error?.response?.data?.message as MessageString);
      }
    });
}

export async function disableEmployee({
  password,
  original,
  disabled,
}: {
  password: string;
  original: any;
  disabled: boolean;
}) {
  const payload: object = {
    cpf: original.cpf,
    createdAt: original.createdAt,
    deleted: disabled,
    deletedAt: original.deletedAt,
    firstName: original.firstName,
    id: original.id,
    lastName: original.lastName,
    managePassword: password,
    permissions: original.permissions,
    updatedAt: original.updatedAt,
  };

  const response = await api
    .put(`${EMPLOYEES_ENDPOINT}/${original.id}`, payload)
    .then((response: AxiosResponse) => response.data)
    .catch((error) => {
      if (error.response) {
        throw new Error(error?.response?.data?.message as MessageString);
      }
    });
  return response?.data;
}

export async function createNewEmployee({
  permissions,
  cpf,
  password,
}: {
  permissions: any;
  cpf: string;
  password: string;
}) {
  const permissionsKeys = Object.keys(permissions);
  const permissionsValues = Object.values(permissions);
  const permissionsAllowed = permissionsValues
    .map((value, index) => {
      if (value === true) {
        return permissionsKeys[index];
      }
      return null;
    })
    .filter((permission) => permission !== null);
  const cleanCpf = cpf.replace(/\D+/g, '');

  const payload: object = {
    permissions: permissionsAllowed,
    employeeDocument: cleanCpf,
    managePassword: password,
  };

  const response = await api
    .post(EMPLOYEES_ENDPOINT, payload)
    .then((response: AxiosResponse) => response.data)
    .catch((error) => {
      if (error.response) {
        throw new Error(error?.response?.data?.message as MessageString);
      }
    });
  return response?.data;
}

export async function getCsvDataEmployees({
  _end,
  _order,
  _sort,
  _start,
  deleted,
}: {
  _end: number;
  _order: string;
  _sort: string;
  _start: number;
  deleted: boolean;
}) {
  const queryStrings = {
    _end,
    _order,
    _sort,
    _start,
    deleted,
  };
  const employees: Employee[] | void = await api
    .get(EMPLOYEES_ENDPOINT, {
      params: queryStrings,
    })
    .then((response: EmployeeResponse) => response.data)
    .catch((error) => {
      if (error.response) {
        throw new Error(error?.response?.data?.message as MessageString);
      }
    });
  if (employees?.length) {
    const sortedEmployees = employees.sort((a, b) =>
      a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0
    );
    return sortedEmployees;
  }
  throw new Error('NO_DATA_FOUND');
}
