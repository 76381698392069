class Permissions {
  static hasFeature = (feature: string) => {
    const selectedPlaces = Permissions.getSelectedPlaces() as Featurable[];
    if (selectedPlaces?.length) {
      return selectedPlaces.some((place) => place?.placeFeatures?.some((feat) => feat.name === feature));
    }
    const place = Permissions.getPlace() as Featurable;
    if (place) {
      return place?.placeFeatures?.some((feat) => feat.name === feature) ?? false;
    }
    return false;
  };

  static hasPermission = (permission: string) => {
    const selectedPlaces = Permissions.getSelectedPlaces() as Featurable[];
    if (selectedPlaces?.length) {
      return selectedPlaces?.some((place) => place?.permissions?.includes(permission));
    }
    const place = Permissions.getPlace() as Featurable;
    if (place) {
      return place?.permissions?.includes(permission) ?? false;
    }
    return false;
  };

  static getPlace = () => {
    const user = JSON.parse(localStorage.getItem('@admin:user')!);
    if (user) {
      const pName = localStorage.getItem('place-id')
        ? JSON.parse(localStorage.getItem('place-id')!)
        : user.employments[0].placeName;
      const place = user.employments.find((place: any) => place.placeName === pName);
      return place;
    }
  };

  static getSelectedPlaces = () => {
    const user = JSON.parse(localStorage.getItem('@admin:user')!);
    if (user) {
      const pNames = localStorage.getItem('selected-places-id')
        ? JSON.parse(localStorage.getItem('selected-places-id')!)
        : [];
      const places = user.employments.filter((place: any) => pNames?.includes(place.placeName));
      return places;
    }
  };

  static hasSeveralPlaces = () => {
    const places = localStorage.getItem('selected-places-id')
      ? JSON.parse(localStorage.getItem('selected-places-id')!)
      : [];
    return places?.length > 1;
  };
}

interface Featurable {
  placeFeatures: { name: string }[];
  permissions: string[];
}

export default Permissions;
