import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, Select, MenuItem, ListSubheader, Checkbox, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { updateFilterOptions, resetPaginationData } from '../../redux/slices/transactions';
import { resetDataList } from '../../redux/slices/global';
import { PATH_DASHBOARD } from '../../routes/paths';
import Permissions from '../../hooks/Permissions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
}));

export function SelectPlaceGroup() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const firstGroup = 0;
  const user = JSON.parse(localStorage.getItem('@admin:user')!);
  const hasEmployments = user?.employments?.length > 0;

  const initialGroupState = localStorage.getItem('group-id')
    ? JSON.parse(localStorage.getItem('group-id')!)
    : hasEmployments
    ? user.employments[firstGroup].placeGroupName
    : [];

  const initialPlacesByGroupState = () => {
    const filterPlacesByGroup = user?.employments?.filter((place) => place?.placeGroupName === initialGroupState);
    return filterPlacesByGroup?.map((place) => place?.placeName);
  };

  const initialSelectedPlaces = localStorage?.getItem('group-selected-places-id')?.length
    ? JSON.parse(localStorage.getItem('group-selected-places-id')!)
    : [];

  const [selectedGroup, setSelectedGroup] = useState(initialGroupState);
  const [selectionListGroups, setSelectionListGroups] = useState([]);

  const [selectionListPlaces, setSelectionListPlaces] = useState(initialPlacesByGroupState());
  const [selectedPlaces, setSelectedPlaces] = useState(initialSelectedPlaces);

  const [lastSelectedPlaces, setLastSelectedPlaces] = useState(initialSelectedPlaces);

  useEffect(() => {
    const mapGroupNames = user.employments.map((place) => place.placeGroupName);
    setSelectionListGroups(mapGroupNames);
    const hasGroupSelected = localStorage?.getItem('group-id')!;
    const hasPlacesSelected = localStorage?.getItem('group-selected-places-id')!;

    if (!hasGroupSelected || !selectionListPlaces || !hasPlacesSelected || !hasPlacesSelected?.length) {
      setSelectedPlaces([]);
      localStorage.setItem('group-selected-places-id', JSON.stringify([]));
      localStorage.setItem('group-id', JSON.stringify(selectedGroup));
      const filterPlacesByGroup = user?.employments?.filter((place) => place?.placeGroupName === selectedGroup);
      const mapPlacesByGroup = filterPlacesByGroup?.map((place) => place?.placeName);
      setSelectionListPlaces(mapPlacesByGroup);
    } else if (hasGroupSelected) {
      hasPlacesSelected ?? setSelectedPlaces(hasPlacesSelected);
      const filterPlacesByGroup = user?.employments?.filter((place) => place?.placeGroupName === selectedGroup);
      const mapPlacesByGroup = filterPlacesByGroup?.map((place) => place?.placeName);
      setSelectionListPlaces(mapPlacesByGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderGroupList = (list) => {
    list = [...new Set(list)];
    return list.map((item, index) => (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    ));
  };

  const renderPlaceToggleList = (list) =>
    list.map((item, index) => (
      <MenuItem
        key={index}
        value={item}
        disableRipple
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onClick={() => handlePlaceToggle(item)}
      >
        <span>{item}</span>
        <Checkbox checked={selectedPlaces.includes(item)} sx={{ pointerEvents: 'none' }} />
      </MenuItem>
    ));

  const handleGroupChange = (event) => {
    const { value } = event.target;
    setSelectedGroup(value);
    if (value) {
      const filterPlacesByGroup = user?.employments?.filter((place) => place?.placeGroupName === value);
      const mapPlacesByGroup = filterPlacesByGroup?.map((place) => place?.placeName);

      setSelectedPlaces(mapPlacesByGroup || []);
      setSelectionListPlaces(mapPlacesByGroup);

      localStorage.setItem('group-selected-places-id', JSON.stringify(mapPlacesByGroup));
      localStorage.setItem('selected-places-id', JSON.stringify(mapPlacesByGroup));
      localStorage.setItem('group-id', JSON.stringify(value));
      localStorage.setItem('place-id', '');
    }
  };

  const handlePlaceToggle = (place) => {
    setSelectedPlaces((prevSelected) => {
      const updatedPlaces = prevSelected.includes(place)
        ? prevSelected.filter((data) => data !== place)
        : [...prevSelected, place];
      localStorage.setItem('selected-places-id', JSON.stringify(updatedPlaces));
      localStorage.setItem('place-id', '');
      return updatedPlaces;
    });
  };

  const compareSelectedPlacesData = (actualData: string[], changedData: string[]): boolean => {
    if (actualData?.length !== changedData?.length) return false;
    const sortedActualData = [...actualData].sort();
    const sortedChangedData = [...changedData].sort();
    return sortedActualData.every((value, index) => value === sortedChangedData[index]);
  };

  const checkGroupChanged = () => {
    const currentGroupId = JSON.parse(localStorage.getItem('group-id') || '[]');
    if (currentGroupId && currentGroupId !== selectedGroup) {
      handlePlacesSelectedChange();
    }
  };

  const handlePlacesSelectedChange = (checkChangedSelection?: boolean) => {
    let updateDashboard = true;
    if (checkChangedSelection) {
      const hasChangedSelectedPlaces = compareSelectedPlacesData(lastSelectedPlaces, selectedPlaces);
      setLastSelectedPlaces(selectedPlaces);
      updateDashboard = !hasChangedSelectedPlaces;
    }
    if (updateDashboard) {
      const actualSelectedPlaces = JSON.parse(localStorage.getItem('selected-places-id')!);
      localStorage.setItem('group-selected-places-id', JSON.stringify(actualSelectedPlaces));

      dispatch(
        updateFilterOptions({
          period: 'ALL',
          status: 'ALL',
          flag: 'ALL',
          product: 'ALL',
          client: '',
        })
      );

      void dispatch(resetDataList(true));
      dispatch(resetPaginationData(true));

      const isIdentificationsPage = location.pathname.includes(PATH_DASHBOARD.identifications.root);
      const isCreditsPage = location.pathname.includes(PATH_DASHBOARD.credits.root);
      const shouldStayOnPage = isIdentificationsPage || isCreditsPage;

      if (shouldStayOnPage) {
        window.location.reload();
      } else {
        history.push(firstViewPathByPermission());
        window.location.reload();
      }
    }
  };

  const firstViewPathByPermission = (): string => {
    const pathsByPermission = {
      CAN_MANAGE_PAYMENT: PATH_DASHBOARD.general.app,
      CAN_MANAGE_USER: PATH_DASHBOARD.employees.root,
      CAN_MANAGE_POST_PRE: PATH_DASHBOARD.cards.root,
      CAN_MANAGE_DEVICE: PATH_DASHBOARD.devices.root,
      CAN_MANAGE_CLIENT: PATH_DASHBOARD.clients.root,
      CAN_MANAGE_KEY: PATH_DASHBOARD.apikeys.root,
    };
    const keys = Object.keys(pathsByPermission);
    const currentValidPath = keys.find(
      (key) => location.pathname.includes(pathsByPermission[key]) && Permissions.hasPermission(key)
    );
    if (currentValidPath) {
      return pathsByPermission[currentValidPath];
    }
    for (let i = 0; i < keys.length; i++) {
      if (Permissions.hasPermission(keys[i])) {
        return pathsByPermission[keys[i]];
      }
    }
    return PATH_DASHBOARD.root;
  };

  const handleSelectAll = () => {
    setSelectedPlaces(selectionListPlaces || []);
    localStorage.setItem('selected-places-id', JSON.stringify(selectionListPlaces));
    localStorage.setItem('group-selected-places-id', JSON.stringify(selectionListPlaces));
    handlePlacesSelectedChange();
  };

  const handleClear = () => {
    setSelectedPlaces([]);
    localStorage.setItem('selected-places-id', JSON.stringify([]));
    localStorage.setItem('group-selected-places-id', JSON.stringify([]));
    handlePlacesSelectedChange();
  };

  return (
    <>
      <ListSubheader
        disableSticky
        disableGutters
        sx={{
          pl: 5,
          mt: 3,
          color: 'text.primary',
          typography: 'overline',
        }}
      >
        rede
      </ListSubheader>
      <Box sx={{ px: 2.5, mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
          <FormControl variant="outlined" className={classes.formControl} sx={{ width: '100%' }}>
            <Select
              labelId="group-label"
              id="group"
              name="group"
              value={selectedGroup || ''}
              onChange={(event) => handleGroupChange(event)}
              onClose={checkGroupChanged}
              displayEmpty
            >
              {renderGroupList(selectionListGroups)}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <ListSubheader
        disableSticky
        disableGutters
        sx={{
          pl: 5,
          color: 'text.primary',
          typography: 'overline',
        }}
      >
        estabelecimento
      </ListSubheader>
      <Box sx={{ px: 2.5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
          <FormControl variant="outlined" className={classes.formControl} sx={{ width: '100%' }}>
            <Select
              id="selectedPlacesByGroup"
              name="selectedPlacesByGroup"
              labelId="selectedPlacesByGroup-label"
              displayEmpty
              multiple
              value={selectedPlaces}
              renderValue={(selected) => (selected.length > 0 ? selected.join(', ') : 'Selecione')}
              onClose={() => handlePlacesSelectedChange(true)}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {renderPlaceToggleList(selectionListPlaces)}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: -0.6,
            color: 'success.main',
            px: 2.8,
            width: '100%',
          }}
        >
          <Button
            onClick={handleSelectAll}
            sx={{
              textTransform: 'none',
              color: 'primary.main',
              padding: 0,
              minWidth: 'auto',
              backgroundColor: 'transparent',
              fontWeight: 600,
              '&:hover': {
                backgroundColor: 'transparent',
                opacity: 0.8,
              },
            }}
          >
            Selecionar tudo
          </Button>
          <Button
            onClick={handleClear}
            sx={{
              textTransform: 'none',
              color: 'primary.main',
              padding: 0,
              minWidth: 'auto',
              backgroundColor: 'transparent',
              fontWeight: 600,
              '&:hover': {
                backgroundColor: 'transparent',
                opacity: 0.8,
              },
            }}
          >
            Limpar
          </Button>
        </Box>
      </Box>
    </>
  );
}
