/* eslint-disable react/jsx-no-duplicate-props */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Button,
  FormControlLabel,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { LoadingButton } from '@material-ui/lab';
import { PlaceEmployeePermissions } from './EmployeesRegisterForm';
import { updatePermissionsEmployee } from '../../utils/employeeServices';
import { resetUpdatedPermissions, disablingToggleOnEditState } from '../../redux/slices/employees';
import { Messages } from '../../@types/messages';

const EmployeeEditForm = ({ employee, onClose }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [requestingEditPermissions, setRequestingEditPermissions] = useState(false);
  const [checkboxError, setCheckboxError] = useState('');

  useEffect(() => {
    const groupPlacesNames = employee.places.map((place) => place.name);
    localStorage.setItem('selected-places-id', JSON.stringify(groupPlacesNames));
  }, [employee.places]);

  const [placePermissions, setPlacePermissions] = useState(() =>
    employee.places.map((place) => ({
      id: place.id,
      name: place.name,
      permissions: Object.keys(PlaceEmployeePermissions).reduce((acc, key) => {
        acc[key] = place.permissions.includes(key);
        return acc;
      }, {}),
    }))
  );

  const handlePermissionChange = (placeIndex, permissionKey) => {
    setPlacePermissions((prevState) => {
      const newState = [...prevState];
      newState[placeIndex] = {
        ...newState[placeIndex],
        permissions: {
          ...newState[placeIndex].permissions,
          [permissionKey]: !newState[placeIndex].permissions[permissionKey],
        },
      };
      return newState;
    });
  };

  const validateCheckbox = () => {
    const hasAnyPermission = placePermissions.some((place) =>
      Object.values(place.permissions).some((permission) => permission)
    );
    setCheckboxError(hasAnyPermission ? '' : 'Selecione pelo menos uma permissão');
    return hasAnyPermission;
  };

  const validatePassword = (value: any) => {
    if (!value || value.length !== 4) {
      setPasswordError('Senha inválida');
      return false;
    }
    setPasswordError('');
    return true;
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    validatePassword(value);
  };

  const handleClickPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSave = async () => {
    if (!validateCheckbox() || !validatePassword(password)) return;

    setRequestingEditPermissions(true);
    try {
      const placePermissionsPayload = placePermissions.map((place) => {
        const allowedPermissions = Object.entries(place.permissions)
          .filter(([, isChecked]) => isChecked)
          .map(([permKey]) => permKey);

        return {
          placeId: place.id,
          permissions: allowedPermissions,
        };
      });

      await updatePermissionsEmployee({
        password,
        original: {
          ...employee,
        },
        placePermissions: placePermissionsPayload,
      });

      dispatch(disablingToggleOnEditState(false));
      dispatch(resetUpdatedPermissions(true));
      enqueueSnackbar(Messages.PERMISSIONS_UPDATE_SUCCESS, { variant: 'success' });
      onClose();
    } catch (error: any) {
      enqueueSnackbar(Messages[error.message], { variant: 'error' });
    } finally {
      setRequestingEditPermissions(false);
    }
  };

  const hasAnyPermissions = placePermissions.some((place) =>
    Object.values(place.permissions).some((permission) => permission)
  );

  return (
    <Box sx={{ p: 0 }}>
      <TextField
        label="CPF funcionário*"
        value={employee.cpf}
        variant="outlined"
        margin="normal"
        disabled
        sx={{ mb: 4 }}
      />

      <FormControl required error={!!checkboxError} component="fieldset" sx={{ width: '100%', mb: 4 }}>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          Permissões por Estabelecimento *
        </Typography>

        {placePermissions.map((place, placeIndex) => (
          <Accordion key={place.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{place.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {Object.entries(PlaceEmployeePermissions).map(([key, label]) => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        checked={place.permissions[key]}
                        onChange={() => handlePermissionChange(placeIndex, key)}
                        name={key}
                        color="primary"
                        size="small"
                      />
                    }
                    label={label}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}

        {checkboxError && <FormHelperText>{checkboxError}</FormHelperText>}
      </FormControl>

      <FormControl sx={{ mb: 4 }}>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          Senha Payface de 4 dígitos
        </Typography>
        <TextField
          required
          name="password"
          id="password"
          label="Sua senha Payface ツ"
          variant="outlined"
          error={!!passwordError}
          helperText={passwordError}
          value={password}
          type={showPassword ? 'text' : 'password'}
          onChange={handlePasswordChange}
          inputProps={{
            maxLength: 4,
            inputMode: 'numeric',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickPassword}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>

      <Box sx={{ mt: 3, display: 'flex', gap: 1 }}>
        <Button variant="outlined" onClick={onClose}>
          Voltar
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleSave}
          pending={requestingEditPermissions}
          disabled={!hasAnyPermissions || password.length !== 4}
        >
          Salvar
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default EmployeeEditForm;
