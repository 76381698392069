import axios from 'axios';
import { Employment } from '../@types/user';
import { url } from '../config';

// ----------------------------------------------------------------------

export const api = axios.create({
  baseURL: url || 'http://127.0.0.1:3000/crm',
});

api.interceptors.request.use((request) => {
  if (request?.headers) {
    const accessToken = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('@admin:user')!);

    if (accessToken) {
      request.headers.authorization = `bearer ${JSON.parse(accessToken)}`;
    } else {
      delete request.headers.authorization;
    }

    if (user) {
      const groupPlacesNames = JSON.parse(localStorage.getItem('selected-places-id')!);
      const groupPlacesIds =
        user.employments
          ?.filter((place: Employment) => groupPlacesNames?.includes(place.placeName))
          ?.map((place: Employment) => place?.placeId) || [];

      if (groupPlacesNames && groupPlacesIds?.length && request.url !== '/profile') {
        request.headers.common['x-group-places-ids'] = groupPlacesIds;
        return request;
      }

      const placeName = JSON.parse(localStorage?.getItem('place-id')!);
      const placeEmployeeData = user?.employments?.find((place: Employment) => place.placeName === placeName);

      if (placeName && placeEmployeeData?.placeId && request.url !== '/profile') {
        request.headers.common['x-place-id'] = placeEmployeeData?.placeId;
        groupPlacesIds?.length && (request.headers.common['x-group-places-ids'] = groupPlacesIds);
      } else {
        delete request.headers.common['x-place-id'];
        delete request.headers.common['x-group-places-ids'];
      }
    }
  }

  return request;
});
