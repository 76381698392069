import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Box, Card, Typography, Tooltip, IconButton, Skeleton } from '@material-ui/core';
import { ComponentsLib } from '@payface-frontends-libs/components';
import { Icon } from '@iconify/react';
import roundHelp from '@iconify/icons-ic/round-help';
import {
  getReport,
  getSwitchOptions,
  getProfile,
  getTransactions,
  getAuthorizersPlace,
} from '../../redux/slices/transactions';
import { resetDataList } from '../../redux/slices/global';
import { TransactionsChartCard, TransactionsDataList, TransactionsFormFilter } from '../../components/_transactions';
import { RootState } from '../../redux/store';

const { Page } = ComponentsLib.Generics;

export function Transactions() {
  const dispatch = useDispatch();
  const { period, _order, _start, _end, report, flag, product, status, csat, resetPagination, isRequestingReport } =
    useSelector((state: RootState) => state.transactions);
  const { reset } = useSelector((state: RootState) => state.global);

  const { cancelled = { balance: 0 }, failed = { balance: 0 }, transactions = { balance: 0 } } = report || {};

  useEffect(() => {
    dispatch(getProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const _sort = 'createdAt';
    if (reset === true) {
      void dispatch(resetDataList(false));
    }
    void dispatch(getReport(period));
    void dispatch(getSwitchOptions({ _end, _order, _sort, _start }));
    void dispatch(
      getTransactions({ _end, _order, _sort, _start, period, authorizer: flag, product, status, user: '', csat })
    );
    void dispatch(getAuthorizersPlace());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, flag, product, _order, reset, status, resetPagination, csat]);

  const renderInfo = (message: string, data: any) => {
    const noneValue = 0;
    const minValue = 1;

    if (data.balance === noneValue) return 'Nenhum valor encontrado';

    if (Number(data.clientCount) === minValue && Number(data.transactionCount) === minValue) {
      return `${message} em ${data.transactionCount} transação de ${data.clientCount} cliente`;
    }

    if (Number(data.clientCount) === minValue && Number(data.transactionCount) !== minValue) {
      return `${message} em ${data.transactionCount} transações de ${data.clientCount} cliente`;
    }

    return `${message} em ${data.transactionCount} transações de ${data.clientCount} clientes`;
  };

  return (
    <Page title="CRM > Pagamentos">
      <Container maxWidth="xl">
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} md={4}>
            <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
                sx={{ flexGrow: 1, minHeight: 383 }}
              >
                <Box display="flex" flexDirection="column">
                  <Box
                    sx={{ height: 10, mt: 1, mb: 1 }}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography variant="h6">Pagamentos</Typography>
                    <Tooltip title="Pagamentos efetivados com sucesso" placement="right" arrow>
                      <IconButton sx={{ color: '#919EAB' }}>
                        <Icon style={{ margin: 2 }} icon={roundHelp} width="20" height="20" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typography color="primary" component="span" variant="h3">
                    {isRequestingReport ? (
                      <Skeleton sx={{ mt: 2, mb: 1 }} animation="wave" variant="rectangular" width={100} height={30} />
                    ) : !transactions ? (
                      'R$ 0,00'
                    ) : (
                      transactions.balanceFormatted
                    )}
                  </Typography>
                  <Typography sx={{ mt: 1 }} color="textSecondary" component="span" variant="caption">
                    {isRequestingReport ? (
                      <Skeleton animation="wave" variant="rectangular" width={100} height={10} />
                    ) : (
                      transactions && renderInfo('Valor pago', transactions)
                    )}
                  </Typography>
                </Box>

                <Box display="flex" flexDirection="column">
                  <Box
                    sx={{ height: 10, mt: 1, mb: 1 }}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography variant="h6">Pagamentos cancelados</Typography>
                    <Tooltip
                      title="Pagamentos efetivados com sucesso que posteriormente foram cancelados pelo caixa ou no CRM"
                      placement="right"
                      arrow
                    >
                      <IconButton sx={{ color: '#919EAB' }}>
                        <Icon style={{ margin: 2 }} icon={roundHelp} width="20" height="20" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typography color="#FFC107" component="span" variant="h3">
                    {isRequestingReport ? (
                      <Skeleton sx={{ mt: 2, mb: 1 }} animation="wave" variant="rectangular" width={100} height={30} />
                    ) : !cancelled ? (
                      'R$ 0,00'
                    ) : (
                      cancelled.balanceFormatted
                    )}
                  </Typography>
                  <Typography sx={{ mt: 1 }} color="textSecondary" component="span" variant="caption">
                    {isRequestingReport ? (
                      <Skeleton animation="wave" variant="rectangular" width={100} height={10} />
                    ) : (
                      cancelled && renderInfo('Valor cancelado', cancelled)
                    )}
                  </Typography>
                </Box>

                <Box display="flex" flexDirection="column">
                  <Box
                    sx={{ height: 10, mt: 1, mb: 1 }}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography variant="h6">Pagamentos não efetivados</Typography>
                    <Tooltip
                      title="Pagamentos não autorizados pela bandeira do cartão de crédito, por falta de crédito em um pagamento pós/pré-pago ou ainda por outro motivo de falha desconhecido"
                      placement="right"
                      arrow
                    >
                      <IconButton sx={{ color: '#919EAB' }}>
                        <Icon style={{ margin: 2 }} icon={roundHelp} width="20" height="20" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typography color="error" component="span" variant="h3">
                    {isRequestingReport ? (
                      <Skeleton sx={{ mt: 2, mb: 1 }} animation="wave" variant="rectangular" width={100} height={30} />
                    ) : !failed ? (
                      'R$ 0,00'
                    ) : (
                      failed.balanceFormatted
                    )}
                  </Typography>
                  <Typography sx={{ mt: 1 }} color="textSecondary" component="span" variant="caption">
                    {isRequestingReport ? (
                      <Skeleton animation="wave" variant="rectangular" width={100} height={10} />
                    ) : (
                      failed && renderInfo('Valor não efetivado', failed)
                    )}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <TransactionsChartCard />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card>
              <TransactionsFormFilter />
              <TransactionsDataList />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
