import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Select, MenuItem, Checkbox, InputLabel } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { resetDataList } from '../../redux/slices/global';
import { resetPaginationData, updateCsat } from '../../redux/slices/transactions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1.5),
    minWidth: 120,
  },
}));

const csatOptions = [
  { value: '5', label: '5' },
  { value: '4', label: '4' },
  { value: '3', label: '3' },
  { value: '2', label: '2' },
  { value: '1', label: '1' },
  { value: 'NULL', label: '-' },
];

export function SelectCsatMultiple() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedCsats, setSelectedCsats] = useState<string[]>(csatOptions.map((opt) => opt.value));
  const [tempSelectedCsats, setTempSelectedCsats] = useState<string[]>(csatOptions.map((opt) => opt.value));

  useEffect(() => {
    const allCsats = csatOptions.map((opt) => opt.value).join(',');
    dispatch(updateCsat(allCsats));
  }, [dispatch]);

  const handleCsatToggle = (csatValue: string) => {
    setTempSelectedCsats((prevSelected) =>
      prevSelected.includes(csatValue)
        ? prevSelected.filter((value) => value !== csatValue)
        : [...prevSelected, csatValue]
    );
  };

  const handleClose = () => {
    setSelectedCsats(tempSelectedCsats);
    const csatToDispatch = tempSelectedCsats.length === 0 ? 'ALL' : tempSelectedCsats.join(',');
    dispatch(updateCsat(csatToDispatch));
    dispatch(resetPaginationData(true));
    void dispatch(resetDataList(true));
  };

  const renderCsatToggleList = () =>
    csatOptions.map((option) => (
      <MenuItem
        key={option.value}
        value={option.value}
        onClick={() => handleCsatToggle(option.value)}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>{option.label}</span>
        <Checkbox checked={tempSelectedCsats.includes(option.value)} sx={{ pointerEvents: 'none' }} />
      </MenuItem>
    ));

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="payments-csat">CSAT</InputLabel>
      <Select
        labelId="csat-label"
        id="csat"
        name="csat"
        multiple
        value={selectedCsats}
        label="CSAT"
        onClose={handleClose}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        renderValue={(selected: string[]) =>
          selected.length === 0
            ? 'Todos'
            : [...selected]
                .sort((a, b) => {
                  if (a === 'NULL') return 1;
                  if (b === 'NULL') return -1;
                  return Number(b) - Number(a);
                })
                .map((value) => csatOptions.find((opt) => opt.value === value)?.label)
                .join(', ')
        }
      >
        {renderCsatToggleList()}
      </Select>
    </FormControl>
  );
}
