import { createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { api } from '../../utils/axios';
import { store } from '../store';
import Permissions from '../../hooks/Permissions';

const POST_PRE_PAID_AUTHORIZER_VALUE = 'PAYFACE_PRE_OR_POST_PAID_CARD';

export const columnsTransationTable = [
  { id: 'date', label: 'Data', width: 170, align: 'left' },
  { id: 'client', label: 'Cliente', width: 250, align: 'right' },
  {
    id: 'value',
    label: 'Valor',
    width: 100,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'installments',
    label: 'Parcelas',
    width: 50,
    align: 'right',
  },
  {
    id: 'flag',
    label: 'Bandeira',
    width: 120,
    align: 'right',
  },
  {
    id: 'product',
    label: 'Produto',
    width: 70,
    align: 'right',
  },
  {
    id: 'csat',
    label: 'CSAT',
    width: 50,
    align: 'right',
  },
  {
    id: 'status',
    label: 'Status',
    width: 50,
    align: 'right',
  },
  {
    id: 'place',
    label: 'Estabelecimento',
    width: 50,
    align: 'right',
  },
];

const paymentsEndpoints = {
  profile: '/profile',
  transactions: '/transactions',
  switch: '/switch-authorizers',
  report: '/transactions/report',
  authorizersPlace: '/authorizers-place',
};

export type PaymentStates = {
  isRequestingProfile: boolean;
  isRequestingReport: boolean;
  isRequestingSwitchOptions: boolean;
  isRequestingTransactions: boolean;
  isRequestingTransaction: boolean;
  errorMessageProfile: string;
  errorMessageReport: string;
  errorMessageSwitchOptions: string;
  errorMessageTransactions: string;
  errorMessageTransaction: string;
  profile: object;
  report: any;
  switchOptions: any;
  transactions: any;
  transaction: any;
  period: string;
  status: string;
  flag: string;
  product: string;
  csat: string;
  listProducts: Array<string>;
  authorizersPlace: any;
  authorizersProducts: any;
  client: string;
  _order: string;
  _end: number;
  _start: number;
  resetPagination: boolean;
  headerCount: number;
  columns: any;
};

const initialState: PaymentStates = {
  isRequestingProfile: false,
  isRequestingReport: false,
  isRequestingSwitchOptions: false,
  isRequestingTransactions: false,
  isRequestingTransaction: false,
  errorMessageProfile: '',
  errorMessageReport: '',
  errorMessageSwitchOptions: '',
  errorMessageTransactions: '',
  errorMessageTransaction: '',
  profile: {},
  report: {},
  switchOptions: [],
  transactions: [],
  transaction: {},
  authorizersPlace: [],
  authorizersProducts: [],
  period: 'ALL',
  status: 'ALL',
  flag: 'ALL',
  product: 'ALL',
  csat: 'ALL',
  listProducts: [],
  client: '',
  _order: 'DESC',
  _end: 10,
  _start: 0,
  resetPagination: false,
  headerCount: 0,
  columns: columnsTransationTable,
};

const slice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    requestingProfile(state) {
      state.isRequestingProfile = true;
    },
    getProfileSuccess(state, action) {
      state.isRequestingProfile = false;
      state.profile = action.payload;
    },
    getProfileFail(state, action) {
      state.isRequestingProfile = false;
      state.errorMessageProfile = action.payload;
    },
    requestingReport(state) {
      state.isRequestingReport = true;
    },
    getReportSuccess(state, action) {
      state.isRequestingReport = false;
      state.report = action.payload;
    },
    getReportFail(state, action) {
      state.isRequestingReport = false;
      state.errorMessageReport = action.payload;
    },
    getPeriod(state, action) {
      state.period = action.payload;
    },
    getStatus(state, action) {
      state.status = action.payload;
    },
    getFlag(state, action) {
      state.flag = action.payload;
    },
    getColumnString(state, action) {
      state.columns = action.payload;
    },
    getProduct(state, action) {
      state.product = action.payload;
    },
    getListProducts(state, action) {
      state.listProducts = action.payload;
    },
    updateCsat(state, action) {
      state.csat = action.payload;
    },
    requestingSwitchOptions(state) {
      state.isRequestingSwitchOptions = true;
    },
    getSwitchOptionsSuccess(state, action) {
      state.isRequestingSwitchOptions = false;
      state.switchOptions = action.payload;
    },
    getSwitchOptionsFail(state, action) {
      state.isRequestingSwitchOptions = false;
      state.errorMessageSwitchOptions = action.payload;
    },
    requestingTransactions(state) {
      state.isRequestingTransactions = true;
    },
    getTransactionsSuccess(state, action) {
      state.isRequestingTransactions = false;
      state.transactions = action.payload.data;
      state.columns = columnsTransationTable.filter((column) =>
        action.payload.hasProductInTransactions ? column : column.id !== 'product'
      );
    },
    getAuthorizersPlace(state, action) {
      const authorizersFullLData = action.payload;
      const listAuthorizers = authorizersFullLData.map((data: any) => ({ [data.id]: data.name }));
      const authorizers: { [Key: string]: string } = {};
      listAuthorizers.forEach((item) => {
        const key = Object.keys(item)[0];
        const value = key === POST_PRE_PAID_AUTHORIZER_VALUE ? 'Pós/pré Pago' : item[key];
        authorizers[key] = value;
      });
      state.authorizersPlace = authorizers;
      state.authorizersProducts = authorizersFullLData?.filter((data: any) => data?.product);
    },
    getTransactionsFail(state, action) {
      state.isRequestingTransactions = false;
      state.errorMessageTransactions = action.payload;
    },
    getFilters(state, action) {
      const { period, status, flag, product, client } = action.payload;
      state.period = period;
      state.status = status;
      state.flag = flag;
      state.product = product;
      state.client = client;
    },
    getOrder(state, action) {
      state._order = action.payload;
    },
    getPaginationStartAndEnd(state, action) {
      const { start, end } = action.payload;
      state._end = end;
      start._start = start;
    },
    getTransactionsHeaders(state, action) {
      state.headerCount = action.payload;
    },
    resetPagination(state, action) {
      state.resetPagination = action.payload;
    },
    requestingTransaction(state) {
      state.isRequestingTransaction = true;
    },
    getTransactionSuccess(state, action) {
      state.isRequestingTransaction = false;
      state.transaction = action.payload;
    },
    getTransactionFail(state, action) {
      state.isRequestingTransaction = false;
      state.errorMessageTransaction = action.payload;
    },
    resetAllStates(state) {
      state.period = 'ALL';
      state.status = 'ALL';
      state.flag = 'ALL';
      state.product = 'ALL';
      state.csat = 'ALL';
      state.client = '';
    },
  },
});

export const { getTransactionsSuccess } = slice.actions;
export default slice.reducer;

// @todo move this function to user slice module to centralize all user actions
export function getProfile() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingProfile());

    try {
      const response = await api.get(paymentsEndpoints.profile);
      const { data } = response.data;
      localStorage.setItem('@admin:user', JSON.stringify(data));
      dispatch(slice.actions.getProfileSuccess(data));
    } catch (error: any) {
      dispatch(slice.actions.getProfileSuccess(error.message));

      throw new Error(error.message);
    }
  };
}

export function getReport(period: string) {
  return async () => {
    const { dispatch, getState } = store;
    const state = getState();
    const { status, product, csat } = state.transactions;

    dispatch(slice.actions.resetPagination(false));
    dispatch(slice.actions.requestingReport());

    const queryStrings = {
      period,
      type: '',
      status: status || '',
      product: product || '',
      csat: csat || '',
    };

    try {
      const response: void | AxiosResponse<any> = await api.get(paymentsEndpoints.report, {
        params: queryStrings,
      });

      dispatch(slice.actions.getReportSuccess(response?.data));
    } catch (error: any) {
      dispatch(slice.actions.getReportFail(error.message));
      throw new Error(error.message);
    }
  };
}

export function getSwitchOptions({
  _end,
  _order,
  _sort,
  _start,
}: {
  _end: number;
  _order: string;
  _sort: string;
  _start: number;
}) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingSwitchOptions());
    dispatch(slice.actions.resetPagination(false));

    try {
      const queryStrings = {
        _end,
        _order,
        _sort,
        _start,
      };
      const response: void | AxiosResponse<any> = await api.get(paymentsEndpoints.switch, {
        params: queryStrings,
      });
      const mapSwitches = response?.data;
      dispatch(slice.actions.getSwitchOptionsSuccess(mapSwitches));
    } catch (error: any) {
      dispatch(slice.actions.getSwitchOptionsFail(error.message));
      throw new Error(error.message);
    }
  };
}

export function getTransactions({
  _end,
  _order,
  _sort,
  _start,
  period,
  authorizer,
  product,
  status,
  user,
  csat,
}: {
  _end: number;
  _order: string;
  _sort: string;
  _start: number;
  period: string;
  authorizer: string;
  product: string;
  status: string;
  user: string;
  csat: string;
}) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingTransactions());
    dispatch(slice.actions.resetPagination(false));

    try {
      const queryStrings: any = {
        _end,
        _order,
        _sort,
        _start,
        period,
      };

      authorizer !== 'ALL' && (queryStrings.authorizer = authorizer);
      product !== 'ALL' && (queryStrings.product = product);
      status !== 'ALL' && (queryStrings.status = status);
      user !== '' && (queryStrings.user = user);
      csat !== 'ALL' && (queryStrings.csatScore = csat);

      const response: void | AxiosResponse<any> = await api.get(paymentsEndpoints.transactions, {
        params: queryStrings,
      });

      dispatch(slice.actions.getListProducts(response?.data.listProducts));
      dispatch(slice.actions.getTransactionsSuccess(response?.data));
      dispatch(slice.actions.getTransactionsHeaders(Number(response?.headers['x-total-count'])));
    } catch (error: any) {
      dispatch(slice.actions.getTransactionsFail(error.message));
      throw new Error(error.message);
    }
  };
}

export function getAuthorizersPlace() {
  const place = Permissions.getPlace();
  return async () => {
    const { dispatch } = store;
    try {
      const response: void | AxiosResponse<any> = await api.get(paymentsEndpoints.authorizersPlace, {
        params: place.placeId,
      });

      dispatch(slice.actions.getAuthorizersPlace(response?.data));
    } catch (error: any) {
      throw new Error(error.message);
    }
  };
}

export function getTransactionById(id: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.requestingTransaction());

    try {
      const response: void | AxiosResponse<any> = await api.get(`${paymentsEndpoints.transactions}/${id}`);
      dispatch(slice.actions.getTransactionSuccess(response?.data));
    } catch (error: any) {
      dispatch(slice.actions.getTransactionFail(error.message));
      throw new Error(error.message);
    }
  };
}

export function updateFilterOptions({
  period,
  status,
  flag,
  product,
  client,
}: {
  period: string;
  status: string;
  flag: string;
  product: string;
  client: string;
}) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.getFilters({ period, status, flag, product, client }));
  };
}

export function updateDirectionOption(direction: boolean) {
  return async () => {
    const { dispatch } = store;
    const order = direction === true ? 'DESC' : 'ASC';
    dispatch(slice.actions.getOrder(order));
  };
}

export function updatePagination(end: number, start: number) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.getPaginationStartAndEnd({ end, start }));
  };
}

export function updatePeriod(period: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.getPeriod(period));
  };
}

export function updateStatus(status: string) {
  return async () => {
    const { dispatch, getState } = store;
    const { period } = getState().transactions;
    dispatch(slice.actions.getStatus(status));
    void dispatch(getReport(period));
  };
}

export function updateProduct(product: string) {
  return async () => {
    const { dispatch, getState } = store;
    const { period } = getState().transactions;
    dispatch(slice.actions.getProduct(product));
    void dispatch(getReport(period));
  };
}

export function updateListProducts(listProducts: any) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.getProduct(listProducts));
  };
}

export function updateFlag(flag: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.getFlag(flag));
  };
}

export function updateColumn(column: any) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.getColumnString(column));
  };
}

export function resetPaginationData(state) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetPagination(state));
  };
}

export function resetTransactionsStates() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.resetAllStates());
  };
}

export function updateCsat(csat: string) {
  return async () => {
    const { dispatch, getState } = store;
    const { period } = getState().transactions;
    dispatch(slice.actions.updateCsat(csat));
    void dispatch(getReport(period));
  };
}
